export const theme = {
  primary: "#D60E41",
  secondary: "#0E3859",
  light: "#fff",
  dark: "#415464",
  success: "#64C3E1",
  fail: "#D60E41",
  disabled: "#F2F2F2",
  disabledBody: "#C3CDD5",
  body: "#3B2B2B",
  bodyFont: "FilsonProBook",
  footerFont: "montserrat",
  bold: 600,
  border: "#64C3E1",
  boxshadow: "rgba(60, 64, 67, 0.15)",
  boxshadowAlt: "rgba(60, 64, 67, 0.3)"
};