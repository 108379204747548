import styled from "styled-components";
import { devices } from "../../styles";

export const Wrapper = styled.div`
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index: 0;
  position: absolute;
  bottom: 0;
  left: 58px;
  height: 88px;
  height: 120px;
  width: 652px;
  @media ${devices.Portrait} {
    top: 850px;
    bottom: none;
  }
  @media ${devices.large} {
    height: 45px;
    left: 265px;
    bottom: 72px;
    width: 940px;
  }
`;

export const MenuButton = styled.div`
  pointer-events: ${({ disabled }) => (disabled ? "none" : "initial")};
  opacity: ${({ disabled }) => (disabled ? "0.2 !important" : 1)};
  display: flex;
  align-items: center;
  color: #ffffff;
  position: relative;
  min-width: 80px;
  min-height: 80px;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;

  svg g {
    cursor: pointer;
    width: 80px;
    height: 80px;
  }

  img {
    height: 32px;
    width: 32px;
    height: 42px;
    width: 42px;
  }

  div {
    svg {
      width: 80px !important;
      height: 80px !important;
      width: 109px !important;
      height: 109px !important;
      position: relative;
      left: 10px;
    }
  }

  @media ${devices.XL} {

    img {
      height: 42px;
      width: 42px;
    }
    div {
      svg {
        width: 109px !important;
        height: 109px !important;
        position: relative;
        left: 18px;
      }
    }
  }

  font-size: 21px;
  line-height: 26px;
`;

export const Arrow = styled.div`
  border: solid white;
  display: inline-block;
  transform: ${(props) => (props.isOpen ? "rotate(45deg)" : "rotate(-135deg)")};
  -webkit-transform: ${(props) =>
    props.isOpen ? "rotate(45deg)" : "rotate(-135deg)"};

  border-width: 0 2px 2px 0;
  padding: 4px;
  margin-top: ${(props) => (props.isOpen ? "0px" : "5px")};
  margin-left: 20px;
  margin-right: 20px;
  background: transparent;

  @media ${devices.XL} {
    border-width: 0 3px 3px 0;
    padding: 6px;
  }
`;

export const AnimationWrapper = styled.div`
  height: 80px;
  width: 80px;
  height: 109px;
  width: 109px;

  @media ${devices.XL} {
    height: 109px;
    width: 109px;
  }
`;

export const TextContainer = styled.div`
  right: 40px;
  margin-left: 10px;
  font-weight: 250;

  @media ${devices.large} {
    margin-left: 12px;
  }
`;

export const ResultsPageWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 50px;
  right: -640px;

  p {
    margin: 0px;
    max-width: 110px;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
    //text-shadow: 0px 4px 5px rgba(14, 56, 89, 0.55), 0px 4px 4px rgba(14, 56, 89, 0.25);
  }
  img {
    width: 159px;
    height: 34px;
  }
`;