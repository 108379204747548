import styled from "styled-components";
import { css } from "styled-components";

const breakpoints = {
  M: 648,
  L: 1026,
  XL: 1200,
  touchscreen: 1800,
  large: process.env.REACT_APP_DEVICE === "ipad" ? 6000 : 1920,

};

export const devices = {
  S: `(max-width: ${breakpoints.M - 1}px)`,
  M: `(min-width: ${breakpoints.M}px)`,
  Portrait: `(min-width: 1024px) and (max-height: 1366px) and (orientation: portrait)`,
  L: `(min-width: ${breakpoints.L}px)`,
  XL: `(min-width: ${breakpoints.XL}px)`,
  touchscreen: `(min-width: ${breakpoints.touchscreen}px)`,
  large: `(min-width: ${breakpoints.large}px)`,
};

export const AppWrapper = styled.main`
  font-family: ${({ theme }) => theme.bodyFont};
  color: ${({ theme }) => theme.dark};
  line-height: 1.3;
  min-height: calc(100vh - 60px);
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  max-width: 1978px;
  margin: auto;
  //overflow: hidden;
  font-size: 14px;
  height: 1024px;

  // margin at the top of the app to account for ICONs header size changing (and hiding the progress bar)
  @media (min-width: 766px) {
    margin-top: 66px;
  }
  @media (min-width: 767px) {
    margin-top: 67px;
  }
  @media (min-width: 1200px) {
    margin-top: 136px;
  }

  @media ${devices.M} {
    padding-bottom: 0px;
  }

  @media ${devices.touchscreen} {
    height: 1080px;
  }

  * {
    font-family: inherit;
  }

  *:focus {
    outline: 2px solid transparent;
  }

  h1 {
    color: ${({ theme }) => theme.primary};
    display: inline-block;
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;
    font-family: FilsonProBlack;

    @media ${devices.M} {
      font-size: 26px;
      line-height: 31px;
    }

    @media ${devices.large} {
      font-size: 36px;
      line-height: 43px;
    }
  }

  h2 {
    color: ${({ theme }) => theme.primary};
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    max-width: 355px;
    margin-bottom: 24px;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: ${({ theme }) => theme.body};
    margin-bottom: 16px;
    display: block;

    @media ${devices.L} {
      font-size: 23px;
      line-height: 40px;
    }
  }

  sup {
    font-size: 0.7em;
    position: relative;
    top: -0.4em;
    vertical-align: top;
  }

  sub {
    vertical-align: bottom;
    font-size: 0.6em;
  }

  small {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;

    sup {
      top: -0.6em;
      vertical-align: inherit;
    }
  }

  em {
    font-style: italic;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  max-width: 1256px;
  
  @media ${devices.large}{
    max-width: 1390px;
  }
`;

export const ContentWrapper = styled.div`
  border-radius: 14px;
  background-color: rgba(255, 255, 255);
  box-shadow: 0 4px 8px 2px rgba(60, 64, 67, 0.15),
    0 1px 3px 0 rgba(60, 64, 67, 0.3);
  max-width: 940px;
  margin-top: 120px;
  padding: 32px;

  @media ${devices.large} {
    margin-top: ${(props) => (props.showICS ? "82px" : "120px")};
    padding: 40px;
  }
`;

export const ButtonOld = styled.button`
  font-family: "Montserrat";
  min-width: 197px;
  min-height: 35px;
  text-align: left;
  border: none;
  color: white;
  border-radius: 20px;
  padding: 10px 5px 10px 15px;
  /* background: transparent; */
  background-color: #0e3859;
  appearance: none;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
  display: inline-block;
  font-weight: ${({ theme }) => theme.bold};
  cursor: pointer;

  &:hover {
    background-color: #d60e41;
  }

  &:disabled {
    background-color: ${({ theme, light }) =>
      light ? theme.light : theme.disabled};
    color: ${({ theme, light }) => (light ? theme.light : theme.disabledBody)};
    cursor: pointer;
  }
`;

export const ButtonStyle = css`
  appearance: none;
  background: white;
  border: 2px solid ${({ theme }) => theme.secondary};
  border-radius: 20px;
  color: #0e3859;
  cursor: pointer;
  display: block;
  font-weight: 500;
  font-family: FilsonProBold;
  margin: 0 auto;
  min-width: 197px;
  padding: 11px 72px 11px 72px;
  min-height: 44px;
  text-decoration: none;
  text-transform: uppercase;
  width: 100%;
  margin-top: 8px;
  font-size: 14px;
  line-height: 18.97px;

  > span {
    padding-right: 80px;
    background: url("icon_btn-arrow_f1.svg") right center no-repeat;
    display: inline-block;

    @media ${devices.M} {
      min-height: 29px;
      padding-top: 2px;
    }

    @media ${devices.large} {
      min-height: none;
      padding-top: 0;
    }
  }

  svg {
    position: relative;
    left: 16px;
    top: 3px;
  }

  @media ${devices.M} {
    width: unset;
    min-height: 54px;
    border-radius: 40px;
    font-size: 18px;
    line-height: 25px;
    min-width: 284px;
  }

  @media ${devices.Portrait} {
      min-width: 410px;
  }

  @media ${devices.L} {
    min-width: 423px;
  }

  @media ${devices.large} {
    min-height: 73px;
    font-size: 24px;
    line-height: 32px;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: ${({ theme }) => theme.primary};
    border: 2px solid ${({ theme }) => theme.primary};
    color: white;
    span {
      background-image: url("icon_btn-arrow_f2.svg");
    }
  }

  &:disabled {
    border: 2px solid
      ${({ theme, light }) => (light ? theme.light : theme.disabled)};
    background-color: ${({ theme, light }) =>
      light ? theme.light : theme.disabled};
    color: ${({ theme, light }) => (light ? theme.light : theme.disabledBody)};
    cursor: default;
    opacity: 0.5;

    span {
      background: url("icon_btn-arrow_f1.svg") right center no-repeat;
    }
  }
`;

export const ButtonPrimary = styled.button`
  ${ButtonStyle}
`;

export const ButtonSecondary = styled.button`
  ${ButtonStyle}
  background-color: ${({ theme }) => theme.secondary};
  color: white;
  overflow: hidden;
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  min-height: 600px;
`;

export const ContentContainer = styled.div`
  position: relative;
  z-index: 0;

  @media ${devices.S} {
    min-height: 0;
  }
  @media ${devices.M} {
    min-height: 0;
  }
`;

export const BackgroundContent = styled.div`
  position: absolute;
  height: 1024px;   //height: 100vh;

  @media ${devices.touchscreen} {
    height: 1080px;
  }
  right: 0;
  left: 0;
  z-index: -1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow: hidden;
  justify-content: center;

  &::before {
    content: "";
    position: fixed;
    bottom: 0;
    background: linear-gradient(178.53deg, rgba(0, 0, 0, 0) 68.47%, rgba(0, 0, 0, 0.55) 98.72%);
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    transition: background 1s linear;
  }

  @media ${devices.S} {
    height: 835px;
    box-shadow: none;
  }

  @media ${devices.M} {
    right: 0;
    img {
      height: 90%;
      right: 10px;
      position: absolute;
    }
  }

  @media ${devices.L} {
    background: transparent; //Videobg
    justify-content: flex-end;
    img {
      height: 90%;
      right: 60px;
    }
  }

  @media (min-width: 1700px) {
    justify-content: center;
  }


  /* Video Placements */
  @media (max-width: 647px) {
    video {
      height: 255px;
      position: absolute;
      top: 0;
    }
  }

  @media ${devices.S} {
    video {
      right: -10px;
    }
  }

  @media (min-width: 648px) and (max-width: 931px) {
    video {
      height: 371px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  @media (min-width: 932px) and (max-width: 1025px) {
    video {
      position: absolute;
      right: 0px;
      top: -122px;
      height: 593px;
    }
  }

  @media ${devices.M} {
    video {
      position: fixed;
      height: 1024px;
      left: -935px;
      top: 0;
    }
  }

  @media ${devices.large} {
    video {
      height: 1080px;
      left: -425px;
    }
  }

  @media ${devices.touchscreen} {
    height: 1080px;
  }
`;
