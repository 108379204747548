import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import { AppWrapper, Container, ContentContainer, devices } from "./styles";
import Intro from "./Components/Intro";
import Question from "./Components/Question";
import Results from "./Components/Results";
import PatientSelector from "./Components/PatientSelector";
import styled, { ThemeProvider } from "styled-components";
import { usePatients } from "./contexts/PatientsContext";
import Progressbar from "./Components/Progressbar";
import useLocalStorage from "./hooks/useLocalStorage";
import { theme } from "./theme";
import Tutorial from "./Components/Tutorial";
import Restart from "./Components/Restart";
import PatientDetailsPage from "./Components/PatientDetails";
import Intersection from "./Components/Intersection";
import Footnotes from "./Components/Footnotes";
import Background from "./Components/Background";
import { references } from "./contexts/questions";
import { abbreviations } from "./contexts/questions";
import QuizBackground from "./Components/QuizBackground";
import { scrollToTop } from "./utils";
import Modal from "./Components/Modal";
import InternalMessage from "./Components/InternalMessage";
import MenuBar from "./Components/MenuBar";
import ReferencesModal from "./Components/ReferencesModal";
import { useReferences } from "./hooks/useReferences";
import Timeout from "./Components/Timeout";
import Login from "./Components/Login";
import VideoPreloader from "./Components/VideoPreloader";

// how long without user interaction until the idle timeout modal appears
const idleTimeout = 1000 * 60;

function App() {
  const patients = usePatients();
  const [name, setName] = useState(null);
  const [show, setShow] = useState(false);
  const [answers, setAnswers] = useLocalStorage(`answer-${name}`, false);
  const [active, setActive] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const [referencesOpen, setReferencesOpen] = useState(false);
  const [referencesContent, setReferencesContent] = useState(null);
  const [abbreviationsContent, setAbbreviationsContent] = useState(null);
  const [isIdle, setIsIdle] = useState(true);
  const [isTimeoutIdle, setIsTimeoutIdle] = useState(false);
  const [showICS, setShowICS] = useState(false);
  const [isPromptReset, setIsPromptReset] = useState(false);
  const [playPromo, setPlayPromo] = useState(false);
  const [videoSrc, setVideoSrc] = useState(
    `./videos/${patients[0].name}_door-1_idle-1.mp4`
  );
  const { content, buttonText } = useReferences(
    referencesContent,
    abbreviationsContent,
    showICS,
    showICS
  );
  const questions = useMemo(
    () =>
      name ? patients.find((patient) => patient.name === name).questions : [],
    [name, patients]
  );
  const isReferencesContentEmpty = useMemo(() => {
    const hasContentAbbreviations =
      abbreviationsContent &&
      abbreviationsContent[0] &&
      abbreviationsContent[0].length;
    const hasContentReferences =
      referencesContent && referencesContent[0] && referencesContent[0].length;

    if (hasContentAbbreviations || hasContentReferences) {
      return false;
    }

    return true;
  }, [referencesContent, abbreviationsContent]);

  // Set to true to include branded header and footer
  const [showLogin, setShowLogin] = useState(process.env.REACT_APP_SHOWLOGIN === "true" ? true : false);
  const [showInternalUse, setShowInternalUse] = useState(process.env.REACT_APP_SHOWINTERNALUSE === "true" ? true : false);
  const [showStandalone, setShowStandalone] = useState(process.env.REACT_APP_SHOWSTANALONE === "true" ? true : false);
  const [showBrand, setShowBrand] = useState(process.env.REACT_APP_SHOWBRAND === "true" ? true : false);

  // * CODE FOR THE IDLE TIMER *
  // when user is idle for timeout length, set isIdle to true which opens the idle modal
  const onIdle = () => {
    const path = location.pathname;

    if (path !== "/") {
      setIsTimeoutIdle(true);
    }
  };

  // when user interacts
  const onActive = () => {
    if (!isTimeoutIdle) {
      reset();
    }
  };

  const { reset, pause, getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: idleTimeout,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    startOnMount: true,
  });

  useEffect(() => {
    if (isTimeoutIdle) {
      pause();
    }
  }, [isTimeoutIdle]);


  const resetIdleTimer = () => {
    setIsTimeoutIdle(false);
    setReferencesOpen(false);
    reset();
  };

  // * END OF IDLE TIMER * 

  useEffect(() => {
    // when url changes, scroll to top of the page if on mobile
    scrollToTop();
  }, [location]);

  const resetProgress = useCallback(() => {
    const bgImage = document.getElementById("background-image");
    if (bgImage) {
      bgImage.style.opacity = "0";
    }
    history.push("/");
    setAnswers(null);
    setIsIdle(true);

    if (bgImage) {
      bgImage.src = `./background-imgs/promo.png`;
      bgImage.style.pacity = "1";
    }
  }, [setAnswers, history]);

  // when location changes, ensure footnotes popup is closed
  useEffect(() => {
    setReferencesOpen(false);
  }, [location]);

  // // update the footnotes when route changes (for non-question pages)
  useEffect(() => {
    if (location.pathname === "/") {
      setReferencesContent(references["/"]);
      setAbbreviationsContent(abbreviations["/"]);
      resetProgress();
    } else if (location.pathname === "/international-statement") {
      setReferencesContent(references["statement"]);
      setAbbreviationsContent(abbreviations["statement"]);
    } else if (location.pathname.includes("tutorial")) {
      setReferencesContent("");
      setAbbreviationsContent("");
      setAnswers(null);
    } else {
      const path = location.pathname.split("/");

      if (references[path[path.length - 1]]) {
        setReferencesContent(references[path[path.length - 1]]);
      }

      if (abbreviations[path[path.length - 1]]) {
        setAbbreviationsContent(abbreviations[path[path.length - 1]]);
      }
    }
  }, [location]);

  const isDetails = location.pathname.includes("/details");
  const isExit = location.pathname.includes("/exit");
  const isIntersection = location.pathname.includes("/intersection");
  const isStatement = location.pathname.includes("/international-statement");

  // if it should include the videos for the quiz (the tower wobbling and falling)
  const isQuiz =
    (location.pathname.includes(`/${name}/`) &&
      !location.pathname.includes("tutorial")) ||
    location.pathname === "/exit";


  return (
    <ThemeProvider theme={theme}>
      {showInternalUse && <InternalMessage />}
      <AppWrapper
        style={{ paddingBottom: showInternalUse ? "40px" : "0" }}
        className={!showBrand ? "standalone" : ""}
      >
        {name && !location.pathname.includes("tutorial") && !referencesOpen && !showICS && !isTimeoutIdle ? (
          <ProgressBarContainer>
            <Progressbar
              name={name}
              questions={questions}
              answers={answers}
              active={active}
              disabled={isDetails || isExit || isIntersection || isStatement}
            />
          </ProgressBarContainer>
        ) : null}

        <ContentContainer
          id="content"
          isResults={location.pathname.includes("/results")}
          isIntro={location.pathname.includes("/intro")}
        >
          <Background
            isHome={location.pathname === "/"}
            playPromo={playPromo}
            showICS={showICS}
            referencesOpen={referencesOpen}
          />

          {isQuiz && (
            <QuizBackground
              answers={answers}
              questions={questions}
              name={name}
              isIdle={isIdle}
              showICS={showICS}
            />
          )}

          <Switch>
            {/* Home page */}
            <Route
              exact
              path="/"
              render={() => (
                <>
                  <Intro
                    setShow={setShow}
                    setName={setName}
                    showStandalone={showStandalone} />
                </>
              )}
            />

            {/* Patient Selector */}
            <Route
              exact
              path="/patient-selector"
              render={() => (
                <PatientSelector
                  setVideoSrc={setVideoSrc}
                  setName={setName}
                  name={name}
                />
              )}
            />
            {/* Render routes for each patient */}
            {patients.map(
              ({ name: patientName, questions, details, fullname }) => {
                // Routes for patient questions

                return [
                  ...questions.map((question, index) => (
                    <Route
                      exact
                      path={`/${patientName}/${index}`}
                      render={() => (
                        <Question
                          key={`${patientName}-${index}`}
                          answers={answers}
                          setAnswers={setAnswers}
                          setActive={setActive}
                          setName={setName}
                          index={index}
                          name={patientName}
                          questions={questions}
                          fullname={fullname}
                          setReferences={setReferencesContent}
                          setAbbreviations={setAbbreviationsContent}
                          setIsIdle={setIsIdle}
                          setShowICS={setShowICS}
                          showICS={showICS}
                        />
                      )}
                    />
                  )),
                  // Route for patient result screen
                  <Route
                    exact
                    path={`/${patientName}/results`}
                    render={() => (
                      <Results
                        setName={setName}
                        name={patientName}
                        setActive={setActive}
                        questions={questions}
                        setReferences={setReferencesContent}
                        setAbbreviations={setAbbreviationsContent}
                        answers={answers}
                        resetProgress={resetProgress}
                        setIsIdle={setIsIdle}
                      />
                    )}
                  />,
                  <Route
                    exact
                    path={`/${patientName}/details`}
                    render={() => (
                      <PatientDetailsPage
                        setName={setName}
                        name={name}
                        patientName={patientName}
                        questions={questions}
                        details={details}
                      />
                    )}
                  />,
                  <Route
                    exact
                    path={`/${patientName}/tutorial`}
                    render={() => (
                      <Tutorial
                        name={name}
                        setName={setName}
                        patientName={patientName}
                        setVideoSrc={setVideoSrc}
                        setShow={setShow}
                      ></Tutorial>
                    )}
                  />,
                  <Route
                    exact
                    path={`/${patientName}/intersection`}
                    render={() => (
                      <Intersection
                        name={patientName}
                        patientName={patientName}
                        setName={setName}
                        details={details}
                        active={active}
                      ></Intersection>
                    )}
                  />,
                ];
              }
            )}
            {/*<Route
              exact
              path="/exit"
              render={() => (
                <Restart
                  setIsPromptReset={setIsPromptReset}
                  setShowICS={setShowICS}
                  name={name}
                  resetProgress={resetProgress}
                />
              )}
              ></Route>*/}
            {/* If route not found redirect to the homepage */}
            <Redirect to="/" />
          </Switch>
        </ContentContainer>

        <Modal
          show={show}
          setShow={setShow}
          showLogin={showLogin}
        />
        {location.pathname !== "/" && location.pathname !== "/exit" && !isTimeoutIdle && (
          <MenuBar
            referencesOpen={referencesOpen}
            setReferencesOpen={setReferencesOpen}
            resetProgress={resetProgress}
            isReferencesContentEmpty={isReferencesContentEmpty}
            referencesButtonText={buttonText}
            showICS={showICS}
            setShowICS={setShowICS}
            setIsPromptReset={setIsPromptReset}
            showStandalone={showStandalone}
          />
        )}
        <ReferencesModal
          isHome={location.pathname === "/"}
          isOpen={referencesOpen}
          setReferencesOpen={setReferencesOpen}
          content={content}
          references={referencesContent}
          abbreviations={abbreviationsContent}
          showICS={showICS}
        />

        {isTimeoutIdle &&
          <Timeout
            setShow={setShow}
            setPlayPromo={setPlayPromo}
            reset={resetIdleTimer} />}

        {isPromptReset && <Restart
          setIsPromptReset={setIsPromptReset}
          setShowICS={setShowICS}
          name={name}
          resetProgress={resetProgress}
        />
        }

        {showLogin && <Login />}
        <VideoPreloader />
      </AppWrapper>
    </ThemeProvider>
  );
}

export default App;

const ProgressBarContainer = styled(Container)`
  max-width: 1256px;
  position: relative;
  top: 55px;

  @media ${devices.large}{
    top: 74px;
    max-width: 1390px;
  }
`;