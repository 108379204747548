import {
  MenuButton,
  Arrow,
  AnimationWrapper,
  TextContainer,
} from "./styles.js";
import ReferencesIcon from "./references.png";

export const AbbreviationsButton = ({
  handleReferencesClick,
  referencesOpen,
  referencesButtonText,
}) => {
  return (
    <div>
      <MenuButton
      menuButton
        data-test="ref-button"
        className="menuButton"
        onClick={() => handleReferencesClick()}
        onKeyPress={() => handleReferencesClick()}
        role="button"
        tabIndex="0"
      >
        <img alt="book" src={ReferencesIcon} />
        <div style={{ marginLeft: "10px", fontWeight: "250" }}>{referencesButtonText}</div>
        <Arrow isOpen={referencesOpen} />
      </MenuButton>
    </div>
  );
};

export const RestartButton = ({
  handleRestartButtonClick,
  referencesOpen,
  exitButton,
  showICS = false,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
      }}
    >
      <MenuButton
        data-test="ics-back-button"
        className="menuButton"
        onClick={() => handleRestartButtonClick()}
        onKeyPress={() => handleRestartButtonClick()}
        role="button"
        tabIndex="0"
      >
        {/* <AnimationWrapper
          ref={(node) => (exitButton.current = node)}
        ></AnimationWrapper> */}
        {showICS || referencesOpen ? (
          <img src={"./back-icon.png"} alt="arrow pointing left" />
        ) : (
          <img
            data-test="restart-button"
            src={"./restart-icon.png"}
            alt="arrow pointing left"
            style={{ color: "white" }}
          />
        )}
        <TextContainer text="restart">
          {showICS || referencesOpen ? "Back" : "Restart"}
        </TextContainer>
      </MenuButton>
    </div>
  );
};
