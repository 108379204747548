// Use [NAME] to replace it with patient name on render
export const shared = {
  right: "You’ve made the right selection for",
  wrong: "There is a better option for",
  selection: "selection for"
};

export const references = {
  "/": "", // Homepage
  "patient-selector": "<p>25(OH)D: 25-hydroxyvitamin D; Ca: calcium; CKD: chronic kidney disease; PO: phosphate; PTH: parathyroid hormone.</p>",
  tutorial: "",
  results: "",
  intersection: "<p>25(OH)D: 25-hydroxyvitamin D; Ca: calcium; CKD: chronic kidney disease; NVD: nutritional vitamin D; PO: phosphate; PTH: parathyroid hormone.</p>",
  statement: "<ol><li>Muñoz M et al. Anaesthesia. 2017;72:233–47.</li><li>Beris P, et al. Br J Anaesthesia. 2008;100(5):599–604.</li>"
};

export const abbreviations = {
  "/": "<p>PBM, patient blood management.</p>", // Homepage
  "patient-selector": "<p>25(OH)D: 25-hydroxyvitamin D; Ca: calcium; CKD: chronic kidney disease; PO: phosphate; PTH: parathyroid hormone.</p>",
  tutorial: "",
  results: "<p>PBM, patient blood management.</p>",
  intersection: "<p>25(OH)D: 25-hydroxyvitamin D; Ca: calcium; CKD: chronic kidney disease; NVD: nutritional vitamin D; PO: phosphate; PTH: parathyroid hormone.</p>",
  statement: "<p>*Definition of anaemia not according to WHO.</p><p>CRP, C-reactive protein; Hb, haemoglobin; ID, iron deficiency; IDA, iron deficiency anaemia; I.V., intravenous; TSAT, transferrin saturation; WHO, World Health Organization.</p>"
};

export const q1 = {
  title: "[NAME] has a number of questions about her upcoming surgery, including what to expect and what can be done to improve her outcomes. Which of the following are accurate ways of describing the concept of Patient Blood Management to her?",
  subheading: "Select all applicable options.",
  answers: [
    { label: "Patient Blood Management is a concept that aims to address modifiable risk factors and is based on three key pillars: optimising red cell mass and iron stores, minimising bleeding and blood loss, and improving anaemia tolerance.", isRight: true },
    { label: "Patient Blood Management is an evidence-based approach that is associated with improved patient outcomes while reducing healthcare costs.", isRight: true },
    { label: "Patient Blood Management is a concept which primarily aims to reduce the need for blood transfusions."}
  ],
  explanation: {
    body:"<p>Patient Blood Management is a concept that aims to address modifiable risk factors via three key pillars, to ultimately improve patient outcomes while reducing healthcare costs.<sup>1,2</sup></p>",
  },
  questionReferences:"",
  questionAbbreviations:"",
  explanationReferences:`<ol><li>International Foundation for Patient Blood Management.<br />https://www.ifpbm.org/ (last accessed February 2024). </li><li>Leahy MF <em>et al. Transfusion.</em> 2017;57:1347–58.</li></ol>`,
  explanationAbbreviations: ""
};

export const q2 = {
  title: "You are conducting Mary's pre-operative assessment. Which of these risk factors should you consider ahead of [NAME]’s surgery to reduce her risks of morbidity, mortality and increased length of hospital stay?",
  subheading: "Select all applicable options.",
  answers: [
    { label: "Transfusion" , isRight: true},
    { label: "Bleeding and blood loss", isRight: true},
    { label: "Anaemia and iron deficiency anaemia (IDA)", isRight: true},
    { label: "None of these"},
  ],
  explanation: {
    body: "<p>Transfusion, bleeding and blood loss, and anaemia/iron deficiency anaemia (IDA) are all risk factors associated with morbidity, mortality and increased length of hospital stay.<sup>1—6</sup></p>",
  },
  questionReferences: "",
  questionAbbreviations:"",
  explanationReferences: `
  <ol>
    <li>Spahn DR and Goodnough LT. <em>Lancet.</em> 2013;381:1855–65.</li>
    <li>Isbister JP. <em>Best Pract Res Clin Anaesthesiol.</em> 2013;27:69–84.</li>
    <li>Leahy MF <em>et al. Transfusion.</em> 2017;57:1347–58.</li>
    <li>Muñoz M <em>et al. Br J Anaesth.</em> 2015;115:15–24.</li>
    <li>Fowler AJ <em>et al. Br J Surg.</em> 2015;102:1314–24.</li>
    <li>Klein AA <em>et al. Anaesthesia.</em> 2016;71:627–35.</li>
  </ol>`,
  explanationAbbreviations: ""
};

export const q3 = {
  title: "Do you think [NAME] is likely to have IDA?",
  subheading: "Select one option.",
  answers: [
    { label: "Yes", isRight: true },
    { label: "No"}
  ],
  explanation: {
    body:"<p>Anaemia prevalence is up to 40% and iron deficiency is the major cause,<sup>1</sup> with up to 72% of anaemic patients being iron deficient.*<sup>2</sup></p>"
  },
  questionReferences: "",
  questionAbbreviations:"<p>IDA, iron deficiency anaemia.</p>",
  explanationReferences: `
    <p>*Absolute and functional iron deficiency.</p>
    <ol>
      <li>Munting KE and Klein AA. <em>Anaesthesia.</em> 2019;74:49–57.</li>
      <li>Muñoz M <em>et al. Anaesthesia.</em> 2017;72:826–34.</li>
    </ol>`,
  explanationAbbreviations: ""
};

export const q4 = {
  title: "As [NAME] is likely to have IDA, when will you investigate for it?",
  subheading: "Select one option.",
  answers: [
    {label: "As soon as possible", isRight: true},
    {label: "2 weeks before surgery"},
    {label: "I won’t investigate for IDA"},
    {label: "Other"}
  ],
  explanation: {
    body:"<p>The diagnosis and treatment of anaemia and iron deficiency should commence as early as possible in the pre-operative period, ideally as soon as the decision to operate is made and at least 30 days before the surgery.<sup>1,2</sup></p>",
  },
  questionReferences: "",
  questionAbbreviations:"<p>IDA, iron deficiency anaemia.</p>",
  explanationReferences: `
      <ol>
        <li>Muñoz M <em>et al. Anaesthesia.</em> 2017;72:233–47.</li>
        <li>Beris P <em>et al. Br J Anaesthesia.</em> 2008;100(5):599–604.</li>
      </ol>
    `,
  explanationAbbreviations: ""
};

export const q5 = {
  title: "[NAME]’s Hb level is 11.5 g/dL. Should you test Mary's serum ferritin levels?",
  subheading: "Select one option.",
  answers: [
    {label: "Yes", isRight: true},
    {label: "No"}
  ],
  explanation: {
    body: "<p>Pre-operative assessment and treatment of IDA has been shown to improve pre- and post-operative haemoglobin levels and decrease the need for blood transfusions. Serum ferritin level is the most sensitive and specific test used for the identification of absolute iron deficiency in the absence of inflammation.<sup>1,2</sup></p>",
  },
  questionReferences: "",
  questionAbbreviations: "<p>Hb, haemoglobin.</p>",
  explanationReferences: `
    <ol>
      <li>Muñoz M <em>et al. Anaesthesia.</em> 2017;72:233–47.</li>
      <li>Goodnough LT <em>et al. Br J Anaesth.</em> 2011;106(1):13–22.</li>
    </ol>
  `,
  explanationAbbreviations: "<p>IDA, iron deficiency anaemia.</p>"
};

export const q6 = {
  title: "The results have come back and [NAME]’s serum ferritin level is 28 ng/mL — is she iron deficient?",
  subheading: "Select one option.",
  answers: [
    {label: "Yes", isRight: true},
    {label: "No"}
  ],
  explanation: {
    body: "<p>A serum ferritin level of <30 ng/mL indicates absolute iron deficiency. In the presence of inflammation (C-reactive protein >5 mg/L and/or transferrin saturation <20%), a serum ferritin level of <100 ng/mL is indicative of iron deficiency.<sup>1</sup></p>"
  },
  questionReferences: "",
  questionAbbreviations: "",
  explanationReferences: `
    <ol>
      <li>Muñoz M <em>et al. Anaesthesia.</em> 2017;72:233–47.</li>
    </ol>
  `,
  explanationAbbreviations: ""
};

export const q7 = {
  title: "Does [NAME]’s IDA diagnosis affect the decision to go ahead with her surgery?",
  subheading: "Select one option.",
  answers: [
    {label: "Yes", isRight: true},
    {label: "No"}
  ],
  explanation: {
    body: "<p>IDA is contraindicated for elective surgery.<sup>1</sup></p><p>Non-urgent surgery should be postponed to allow the diagnosis and treatment of IDA.<sup>2,3</sup></p>"
  },
  questionReferences: "",
  questionAbbreviations: "<p>IDA, iron deficiency anaemia.</p>",
  explanationReferences:`
      <ol>
        <li>Hofmann A <em>et al. Curr Opin Anaesthesiol.</em> 2012;25(1):66–73.</li>
        <li>Muñoz M <em>et al. Anaesthesia.</em> 2017;72:233–47.</li>
        <li>International Foundation for Patient Blood Management.<br />https://www.ifpbm.org/ (last accessed February 2024).</li>
      </ol>
  `,
  explanationAbbreviations: "<p>IDA, iron deficiency anaemia.</p>"  
};

export const q8 = {
  title: "What therapeutic option will you choose to treat [NAME]’s IDA?",
  subheading: "Select one option.",
  answers: [
    { label: "Oral iron"},
    { label: "I.V. iron", isRight: true}, 
    { label: "No treatment is required"},
    { label: "Other "}
  ],
  explanation: {
    body:"<p>I.V. iron should be used if surgery is planned for <6 weeks after the diagnosis of iron deficiency.<sup>1</sup></p><p>Mary’s surgery is in 5 weeks. She needs rapid iron delivery, making I.V. iron an appropriate option for her.<sup>1</sup></p>",
  },
  explanationLink: {
    body:"International consensus statements on peri-operative ID/IDA management",
  },
  ICS: {
    title: "International consensus statements on peri-operative ID/IDA management",
    assessment : "<p><span>Assessment:</span> For expected moderate&#8211;high blood loss (>500 mL) patients should be assessed for iron status (serum iron, ferritin and transferrin saturation) as soon as the decision for surgery is undertaken<sup>1</sup> or at least 30 days before surgery.<sup>2</sup></p>",
    recommendation : "Recommendation for classification and treatment of peri-operative anaemia<sup>1</sup>",
    promo: " <p><strong>Iron deficient non-anaemic patients</strong> undergoing surgical procedures with moderate to high blood loss may benefit from pre-operative iron treatment</p>",
    disclaimer: "<small>Adapted from Muñoz M <em>et al.</em> 2017.<sup>1</sup></small>",  },
  questionReferences: "",
  questionAbbreviations:"<p>IDA, iron deficiency anaemia; I.V., intravenous.</p>",
  explanationReferences:"<ol><li>Muñoz M <em>et al. Anaesthesia.</em> 2017;72:233–47.</li></ol>",
  explanationAbbreviations: "<p>ID, iron deficiency; IDA, iron deficiency anaemia; I.V., intravenous.</p>",
  abbreviationICS: "<p>*Definition of anaemia not according to WHO.</p><p>CRP, C-reactive protein; Hb, haemoglobin; ID, iron deficiency; IDA, iron deficiency anaemia; I.V., intravenous; TSAT, transferrin saturation; WHO, World Health Organization.</p>",
  referenceICS: "<ol><li>Muñoz M <em>et al. Anaesthesia.</em> 2017;72:233–47.</li><li>Beris P <em>et al. Br J Anaesthesia.</em> 2008;100(5):599–604.</li></ol>"
};

export const q9 = {
  title: "The time has come for [NAME]’s surgery. Her Hb level reading is 13.5 g/dL — can Mary go ahead with her surgery?",
  subheading: "Select one option.",
  answers: [
    {label: "Yes", isRight: true},
    {label: "No"}
  ],
  explanation: {
    body: "<p>When treating IDA pre-operatively, the target Hb concentration should be ≥13 g/dL in both sexes, to minimise the risk of transfusion-associated unfavourable outcomes.<sup>1</sup></p>"
  },
  questionAbbreviations: "<p>Hb, haemoglobin.</p>",
  questionReferences: "",
  explanationAbbreviations: "<p>Hb, haemoglobin; IDA, iron deficiency anaemia.</p>",
  explanationReferences: "<ol><li>Muñoz M <em>et al. Anaesthesia</em>. 2017;72:233–47.</li></ol>​"
};