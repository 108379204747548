import gsap from "gsap/gsap-core";
import React, { useEffect,  useLayoutEffect} from "react";
import sanitizeHtml from "sanitize-html";
import { swapName } from "../../utils";
import { Assessment, BodyCopy, Disclaimer, Image, Promo, Title } from "./styles";
import { q8 } from "../../contexts/questions";

export default function Statement({ setShowICS }) {
  
  useEffect(() => {
    /* Event Tracking 
    Event Category: Ecommerce
    Event Action: Add to Wishlist
    Event Name: Smartphone
    Event Value: 1000 */
    window._paq.push([
      'trackEvent', 
      'PBMSIM', 
      `Page Navigation`, 
      process.env.REACT_APP_IPAD === "true" ? 'IPad' : 'Touchscreen',
      `International consensus statements on peri-operative ID/IDA management`]);
  }, []);

  useLayoutEffect(() => {
    const wrapperAnimation = gsap.fromTo(
      ".wrapper",
      { height: 0 },
      {
        height: document.getElementById('ICS').offsetHeight + 65,
        duration: 0.5,
      }
    );
    wrapperAnimation.then(() => {});
  }, []);

  useLayoutEffect(() => {
    gsap.fromTo(".fadeIn", { autoAlpha: 0 }, { autoAlpha: 1, stagger: 0.05 });
  }, []);

  return (
      <div id="ICS">
        <Title className="questionContent fadeIn moveUp" dangerouslySetInnerHTML={{__html: q8.ICS.title}} />
        <Assessment className="questionContent fadeIn moveUp" dangerouslySetInnerHTML={{__html: sanitizeHtml(swapName(q8.ICS.assessment,`surgery`)) }} />
        <BodyCopy className="questionContent fadeIn moveUp" dangerouslySetInnerHTML={{__html: q8.ICS.recommendation }} />
        <Image>
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet="./statement-idida-mob.svg 767w"
              sizes="767px"
            />
            <source
              srcSet="./statement-idida.svg 1280w"
              sizes="1280px"
            />
            <img 
              width={screen.width > 767 ? "860" : "345" }
              height={screen.width > 767 ? "323" : "917" }
              className="questionContent fadeIn moveUp"  
              src="./statement-idida.svg" 
              alt="International consensus statements on peri-operative ID/IDA management" 
            />
          </picture>
        </Image>
        <Promo className="questionContent fadeIn moveUp" dangerouslySetInnerHTML={{__html: q8.ICS.promo }} />
        <Disclaimer className="questionContent fadeIn moveUp" dangerouslySetInnerHTML={{__html: q8.ICS.disclaimer }} />
      </div>
  );
}
