import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { q8 } from "../contexts/questions";
import { dictionary } from "../contexts/site";

export function useReferences(
  references = null,
  abbreviations = null,
  includeFootnotes = false,
  showICS = false,
) {
  const location = useLocation();
  const docReferences = new DOMParser().parseFromString(
    references,
    "text/html"
  );
  const docAbbreviations = new DOMParser().parseFromString(
    abbreviations,
    "text/html"
  );

  const listReferences = docReferences.querySelectorAll("li");
  const listAbbreviations = docAbbreviations.querySelectorAll("p")[0]
    ? docAbbreviations.querySelectorAll("p")[0].innerHTML.split(";")
    : 0;

  const content = useMemo(() => {
    const isAbbreviations =
      !!abbreviations && abbreviations[0] && abbreviations[0].length;
    const isReferences = !!references && references[0] && references[0].length;

    if (showICS){
      return q8.abbreviationICS + q8.referenceICS;
    }


    if (isAbbreviations && isReferences) {
      return abbreviations + references;
    }

    if (isAbbreviations) {
      return abbreviations;
    }

    if (isReferences) {
      return references;
    }

    return [""];
  }, [references, abbreviations, showICS]);

  const buttonText = useMemo(() => {
    const isAbbreviations =
      !!abbreviations && abbreviations[0] && abbreviations[0].length;
    const isReferences = !!references && references[0] && listReferences.length;
    const isFootnotes = (location.pathname.includes('/2') && !isAbbreviations) || showICS;

    const ABBREVIATIONS_COUNT = listAbbreviations ? listAbbreviations.length : 0;
    const REFERENCES_COUNT = isReferences ? isReferences : 0;

    
    if (isAbbreviations && isReferences && isFootnotes){
      if (ABBREVIATIONS_COUNT > 1){
        if (REFERENCES_COUNT > 1 || showICS){
          return dictionary.footnotes.footnotesAbbreviationsReferences;
        }
        
        return dictionary.footnotes.footnotesAbbreviationsReference;
      }

      if (REFERENCES_COUNT > 1){
        return dictionary.footnotes.footnotesAbbreviationReferences;
      }
      
      return dictionary.footnotes.footnotesAbbreviationReference;
    }

    if (isAbbreviations && isReferences){
      if (ABBREVIATIONS_COUNT > 1){
        if (REFERENCES_COUNT > 1){
          return dictionary.footnotes.abbreviationsReferences;
        }
        
        return dictionary.footnotes.abbreviationsReference;
      }

      if (REFERENCES_COUNT > 1){
        //console.log("HERE")
        return dictionary.footnotes.abbreviationReferences;
      }
      
      return dictionary.footnotes.abbreviationReference;
    }

    if (isReferences && isFootnotes){
      if (REFERENCES_COUNT > 1){
        return dictionary.footnotes.footnoteReferences;
      }

      return dictionary.footnotes.footnoteAndReference;
    }
    
    if (isAbbreviations){
      if (ABBREVIATIONS_COUNT > 1){
        return dictionary.footnotes.abbreviations;
      }
      
      return dictionary.footnotes.abbreviation;
    }
    
    if (isReferences){
      if (REFERENCES_COUNT > 1){
        return dictionary.footnotes.references;
      }
      
      return dictionary.footnotes.reference;
    }

    
    return dictionary.footnotes.references;
  }, [references, abbreviations, includeFootnotes]);

  return { content, buttonText };
}
