import gsap from "gsap/gsap-core";
import React, { useEffect, useLayoutEffect } from "react";
import TagManager from "react-gtm-module";
import { useHistory } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { dictionary } from "../../contexts/site";
import { ButtonPrimary, ButtonSecondary, Container, ContentWrapper, devices } from "../../styles";

export default function Restart({setIsPromptReset, setShowICS, name = "Mary", resetProgress }) {
  const history = useHistory();

  // useLayoutEffect(() => {
  //   gsap.fromTo(".fadeIn", { autoAlpha: 0 }, { autoAlpha: 1, stagger: 0.2 });
  // }, [])
  
  useEffect(() => {
    if (!name) {
      history.push("/");
    }
  }, [name, history]);

  // return <div>alksdjfklsjf</div>

  return !name ? null : (
    <Container 
      data-test="restart-modal"
      style={{
      "position": "fixed",
      "left": "0", 
      "right": "0", 
      "bottom": "0", 
      "top" : "15%", 
      "zIndex": "10",
      "width" : "100%",
      "margin": "0",
      "maxWidth": "none"
      }}>
     
      <MobWrapper >
        <RestartWrapper>
            <h1 className="fadeIn">{dictionary.buttons.startAgain}</h1>
            <p 
              style={{"fontSize" : "21px", "lineHeight": "30px", "fontWeight": "300"}} 
              className="fadeIn"
            >
              {dictionary.start.restartPara}
            </p>
            <hr className="fadeIn" />
            <ButtonsContainer>
              <ConfirmButton  
                  data-test="restart-button-true"
                  light
                  onClick={() => {
                    // analytics for clicking no button
                    // const tagManagerArgs = {
                    //   dataLayer: {
                    //     event: "gaQuizEvent",
                    //     eventAction: `${name}`,
                    //     eventLabel: `Restart Button - Yes`,
                    //   },
                    // };
                    // TagManager.dataLayer(tagManagerArgs);
                    setIsPromptReset(false);
                    resetProgress();
                  }}>
                  {dictionary.buttons.giveUp}
              </ConfirmButton>
              <DenyButton 

                light
                onClick={() => {
                  // analytics for clicking no button
                  // const tagManagerArgs = {
                  //   dataLayer: {
                  //     event: "gaQuizEvent",
                  //     eventAction: `${name}`,
                  //     eventLabel: `Restart Button - No`,
                  //   },
                  // };
                  // TagManager.dataLayer(tagManagerArgs);
                  
                  setShowICS(false);
                  setIsPromptReset(false);
                  //history.goBack();
                }}
              >
               {dictionary.buttons.never}
              </DenyButton>
            </ButtonsContainer>
        </RestartWrapper>
      </MobWrapper>
    </Container>
  );
}


const FadeInBackground = keyframes`
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(14,56,89,0.75);
  }
` 

const RestartWrapper = styled(ContentWrapper)`
  position: relative;
  /* background-color: rgba(65, 84, 100, 0.9); */
  //color: ${({ theme }) => theme.light};
  text-align: center;
  margin-left: 0;
  margin-right: 0;
  height: 100%;
  
  @media ${devices.M} {
    margin-left: 58px;
    margin-right: 58px;
  }

  @media ${devices.L} {
    margin-left: 0;
    margin-inline: auto;
    margin-bottom: 316px;
    padding: 56px;
  }

  &::before {
    content: "";
    background-color: rgba(14,56,89,0.75);
    animation: ${FadeInBackground} 0.2s ease-in; 
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0;
    left: 0px;
    z-index: -1; 
    //height: 100vh;
  }

  hr {
    background-color: ${({ theme }) => theme.border};
    border: none;
    height: 1px;
    width: 80px;
    margin: 15px auto;

    @media ${devices.M} {
      margin: 20px auto;
    }

    @media ${devices.M}{
      margin-block: 24px 32px;
    }
  }
  
  h1 {
    color: ${({ theme }) => theme.primary};
    display: inline-block;
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;

    @media ${devices.M} {
      font-size: 26px;
      line-height: 30px;
    }

    @media ${devices.L} {
      max-width: 442px;
      font-size: 36px;
    }
  }

  p {
    font-size: 14px;
    line-height: 1.63;
    margin: 30px 0 0;

    @media ${devices.M} {
      line-height: 1.5;
    }
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  button {
    min-width: 260px;
  }

  ${ButtonPrimary}:not(:last-child) {
    margin-bottom: 24px;
    @media ${devices.S} {
      margin-bottom: 15px;
    }
  }
`;

const MobWrapper = styled.div`
  @media ${devices.S} {
    height: 100%;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 2;
  }
`;



const ConfirmButton = styled(ButtonPrimary)`
  border: 3px solid #0E3859;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 426px;
  margin: 0px;
`;

const DenyButton = styled(ButtonSecondary)`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px; 
  width: 426px;
  
  &:hover {
    border: none;
  }
`;
