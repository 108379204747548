import gsap from "gsap";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import sanitizeHtml from "sanitize-html";
import Statement from "../../Components/Statement";
import { scrollToTop, swapName } from "../../utils";
import useProgress from "../../hooks/useProgress";
import { Container, ButtonSecondary } from "../../styles";
import {
  AnswerExplanation,
  AnswerLabel,
  AnswerWrap,
  ExplanationTitle,
  FormOptions,
  QuestionPagination,
  QuestionSubtitle,
  QuestionTitle,
  ThumbsDownContainer,
  Wrapper,
  ExplanationLink,
  Submit,
  ExplanationContent,
} from "./styles";
import { shared } from "../../contexts/questions";
import { dictionary } from "../../contexts/site";

const buttonStates = { INITIAL: "initial", SUCCESS: "success", FAIL: "fail" };

const { INITIAL, SUCCESS, FAIL } = buttonStates;

export default function Question({
  name,
  index,
  questions,
  answers,
  setAnswers,
  setActive,
  setName,
  setReferences,
  setAbbreviations,
  fullname,
  setIsIdle,
  setShowICS,
  showICS,
}) {
  const [canSubmit, setCanSubmit] = useState(true);
  const [showExplanation, setShowExplanation] = useState(false);
  const answerExplanation = useRef();
  const submitButton = useRef();
  const [buttonState, setButtonState] = useState(INITIAL);
  const history = useHistory();
  const { progress, score } = useProgress(answers, questions);

  // update footnotes for the current question
  useEffect(() => {
    setReferences(
      [questions[index].questionReferences]
    );
    setAbbreviations(
      [questions[index].questionAbbreviations]  
    );
  }, [index, questions, setReferences, setAbbreviations, progress]);

  const onShowICS = (event) => {
    event.preventDefault();
    setShowICS(true);
  }

  const onChange = useCallback(
    (event) => {
      const { type, checked, value } = event.target;
      const newValues = { ...answers };

      // checkboxes
      if (type === "checkbox") {
        // if there's no value attached to the key, create a new array
        if (!newValues[index]) {
          newValues[index] = [];
        }

        // if the target is checked push the array, if not remove it from the array
        checked
          ? newValues[index].push(value)
          : (newValues[index] = newValues[index].filter(
              (item) => item !== value
            ));
        setAnswers(newValues);
        return;
      }

      // on radiobutton save the value of the button
      newValues[index] = value;
      setAnswers(newValues);
    },
    [setAnswers, index, answers]
  );

  const isMultipleChoice = useMemo(() => {
    let nr = 0;

    // check if there's more than one right answer
    questions[index].answers.forEach((element) => {
      if (element.isRight) {
        nr += 1;
      }
    });

    return nr > 1;
  }, [index, questions]);

  const submit = useCallback(() => {

    setIsIdle(false);
    setShowICS(false);

    submitButton.current.style.display = 'none';
    submitButton.current.style.visability = 'hidden';
    const isRightAnswer = () => {
      // get submitted answers
      const submittedAnswer = answers[index];
      const possibleAnswers = questions[index].answers;

      // If simple radio buttons, check if the answer is right
      if (!isMultipleChoice) {
        return Boolean(possibleAnswers[submittedAnswer].isRight);
      }

      // if checkboxes get all right answers
      let rightAnswers = [];
      possibleAnswers.forEach((elem, index) => {
        if (elem.isRight) {
          rightAnswers.push(index.toString());
        }
      });

      // if user has the same amount of answers as the right answers
      if (rightAnswers.length === submittedAnswer.length) {
        // go through the submitted answers and filter them from possible answers
        submittedAnswer.forEach((elem) => {
          rightAnswers = rightAnswers.filter((right) => {
            return right !== elem;
          });
        });

        // if user got all right answers return true
        return rightAnswers.length === 0;
      }

      return false;
    };

    /* Event Tracking 
    Event Category: Ecommerce
    Event Action: Add to Wishlist
    Event Name: Smartphone
    Event Value: 1000 */
    window._paq.push([
      'trackEvent', 
      'PBMSIM', 
      `Quiz Response ${name}`, 
      process.env.REACT_APP_IPAD === "true" ? 'IPad' : 'Touchscreen',
      `Step ${4 + 2 * index} - ${name} - ${index} - ${answers[index]}`
    ]);

    // disable submit button
    setCanSubmit(false);

    // make answer explanation active
    // setShowExplanation(true);
    const timeline = gsap.timeline({ repeat: 0, repeatDelay: 0 });
    let elements;

    if (isRightAnswer()) {
      elements = [...document.querySelectorAll(".questionContent")].reverse();
    } else {
      elements = [
        ...document.querySelectorAll(".questionContent"),
        ".submit-button",
      ].reverse();
    }

    // fade out text elements
    const animation = timeline.fromTo(
      elements,
      { autoAlpha: 1, y: 0 },
      {
        autoAlpha: 0,
        duration: 0.5,
      }
    );

    if (!isRightAnswer()) {
      // play the quiz background video
      const video = document.getElementById("video");
      if (video) {
        video.pause();
        video.currentTime = 0;
        setTimeout(() => {
          video.play();
          video.addEventListener('ended', myVideoFinish,false )
        }, 1000);
      }
    }

    if (isRightAnswer()) {
      const video = document.getElementById("video-hidden-correct");
      if (video) {
        video.pause();
        video.currentTime = 0;
        setTimeout(() => {
          video.play();
          video.addEventListener('ended', myVideoFinish, false )
        }, 1000);
      }
    }

    function myVideoFinish() {
      if(index > 8){
        const video = document.getElementById("video-hidden");
        if (video) {
          video.setAttribute("src", `./videos/${name}_stage_final_${score <= 3 ? "fail" : "success"}.mp4`);
          video.pause();
          video.currentTime = 0;
          video.style.zIndex = 10;
          video.style.opacity = 1;
        }
      } else {
        const video = document.getElementById("video-hidden-correct");
        if (video) {
          video.setAttribute("src", `./videos/Mary_door-${index-1}_corridor.mp4#t=0.01`);
          video.pause();
          video.currentTime = 0;
        }
      }
    }

    animation.add(() => {
      if (isRightAnswer()) {
        // On right answer
        setButtonState(SUCCESS);
      }
    });

    // if answer is wrong, fade out white container box and contents
    if (!isRightAnswer()) {
      animation.to(
        ".wrapper",
        {
          height: document.getElementsByClassName("wrapper")[0].offsetHeight,
          duration: 0.5,
        },
        `afterFadeout`
      );
    } else {
      animation.to(
        ".wrapper",
        {
          height: document.getElementsByClassName("wrapper")[0].offsetHeight,
          duration: 0.5,
        },
        `afterFadeout`
      );
    }

    // remove text elements
    animation.set(elements, { display: "none" });

    // after the animation
    if (!isRightAnswer()) {
      // scroll to top of the page if on mobile
      scrollToTop();

      // 1 second delay before showing the white answer explanation box after user answers incorrectly
      setTimeout(() => {
        setButtonState(FAIL);
        setShowExplanation(true);
        setReferences(questions[index].explanationReferences);
        setAbbreviations(questions[index].explanationAbbreviations);
      }, 1000);
    } else {
      animation.then(() => {
        setShowExplanation(true);
        setReferences(questions[index].explanationReferences);
        setAbbreviations(questions[index].explanationAbbreviations);

        // scroll to top of the page if on mobile (scroll after thumbs up animation if correct answer)
        scrollToTop();
      });
    }
  }, [score, index, questions, answers, isMultipleChoice, setCanSubmit, name, setReferences, setAbbreviations, setIsIdle]);

  useEffect(() => {
    let answerExplanationAnimation = gsap.timeline();

    if (showExplanation && answerExplanation.current) {
      if (progress[index] === "right") {
        answerExplanationAnimation.to(
          ".wrapper",
          {
            height: answerExplanation.current.offsetHeight + 65,
          },
          `growExplanation+=0.5`
        );
      } else if (progress[index] === "wrong") {
        answerExplanationAnimation.to(
          ".wrapper",
          {
            height: answerExplanation.current.offsetHeight + 60,
          },
          `growExplanation+=0.5`
        );
      }

      answerExplanationAnimation.then(() => {
        // add the wrapper (only needed for wrong answer because wrapper shouldn't fade out if answer is right, and it fades out for correct answer on firefox if not)
        if (progress[index] === "wrong") {
          answerExplanationAnimation.to(".wrapper", {
            backgroundColor: "rgba(255, 255, 255)",
            //backgroundImage: "url('card-bg.gif')",
            backgroundPosition: "bottom center",
            backgroundRepeat: "repeat-x",
            boxShadow:
              "0 4px 8px 2px rgba(60, 64, 67, 0.15), 0 1px 3px 0 rgba(60, 64, 67, 0.3)",
          });
        }

        // add the text content inside the wrapper
        answerExplanationAnimation.fromTo(
          ".answer-explanation > *",
          { autoAlpha: 0 },
          {
            autoAlpha: 1,
            duration: 0.5,
            stagger: 0.1,
          }
        );
        answerExplanationAnimation.to(
          ".answer-explanation",
          {
            autoAlpha: 1,
          },
          `growExplanation+=0.5`
        );
      });
    }

    return () => {
      answerExplanationAnimation.kill();
    };
  }, [showExplanation, progress, index]);

  useLayoutEffect(() => {

    // fade in text elements
    const elements = [...document.querySelectorAll(".fadeInDown")];

    const textAnimation = gsap.fromTo(
      elements,
      { autoAlpha: 0, y: -10 },
      {
        autoAlpha: 1,
        y: 0,
        duration: 0.5,
        stagger: 0.1,
      }
    );

    textAnimation.then(() => {
      setButtonState(INITIAL);
    });

    // kill animation on unmount
    return () => {
      textAnimation.kill();
    };
  }, []);

  useEffect(() => {
    if(window.location.href.includes("Mary/0")){
      setAnswers(null)
    }
   
    setActive(index);
  }, [setActive, index]);

  const goNext = () => {

    setIsIdle(true);
    setShowICS(false);

    /* Event Tracking 
    Event Category: Ecommerce
    Event Action: Add to Wishlist
    Event Name: Smartphone
    Event Value: 1000 */
    window._paq.push([
      'trackEvent', 
      'PBMSIM', 
      `Quiz Response ${name}`, 
      process.env.REACT_APP_IPAD === "true" ? 'IPad' : 'Touchscreen', 
      `Step ${5 + 2 * index} - ${name} - ${index} - ${progress[index] === "right" ? "Correct" : "Incorrect"}`
    ]);

    // before going to the next page fade out container
    const wrapperAnimation = gsap.to(".wrapper", {
      autoAlpha: 0,
      duration: 0.5,
    });

    // after animation done
    wrapperAnimation.then(() => {
      // if there's another question go to the next question page
      // if it's the last question go to the results page
      if (index < questions.length - 1) {
        history.push(`/${name}/${index + 1}`);
      } else {
        history.push(`/${name}/results`);
      }
    });
    setReferences("");
    setAbbreviations("");
  };

  useEffect(() => {
    setName(name);
  }, [name, setName]);

  useEffect(() => {
    // if show explanation, set index to next question so the progress bar updates current question with tick/cross
    if (showExplanation) {
      setActive(index + 1);
    }
  }, [showExplanation, setActive, index]);


  useEffect(() => {
    if (showExplanation && answerExplanation.current) {
      answerExplanation.current.focus();
    }
  }, [showExplanation]);

  useEffect(() => {

    if(showICS){

      const video = document.getElementById("currentVideo");
      video.play();

      let icsAnimation = gsap.timeline();
      icsAnimation.to(
        ".wrapper",
        {
          height: document.getElementById("ICS").offsetHeight + 85,
        },
        `growExplanation+=0.5`
      );
    }
    
  }, [showICS]);

  return (
    <Container>
      <Wrapper className="wrapper" showICS={showICS}>
          <>
            <QuestionPagination
              className="questionContent"
              dangerouslySetInnerHTML={{__html: sanitizeHtml(dictionary.pagination.question+` `+(index+1)+` `+ dictionary.pagination.of)}} 
             />
            <QuestionTitle
              className="questionContent"
              dangerouslySetInnerHTML={{
                __html: swapName(questions[index].title, fullname),
              }}
            ></QuestionTitle>
            <QuestionSubtitle
              className="questionContent"
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  swapName(questions[index].subheading, fullname)
                ),
              }}
            ></QuestionSubtitle>
            <form>
              <FormOptions>
                {questions[index].answers.map(({ label }, answerIndex) => {
                  const value = answerIndex.toString();
                  const isChecked = () => {
                    if (!answers) {
                      return false;
                    }
                    // radio buttons
                    if (!isMultipleChoice) {
                      return Boolean(
                        answers[index] && answers[index].toString() === value
                      );
                    }

                    //checkboxes
                    return Boolean(
                      answers[index] &&
                        answers[index].find((elem) => elem === value)
                    );
                  };

                  const inputId = `answer-${index}-${answerIndex}`;

                  let fullWidth, noMargin;
                  if (index === 0) {
                    fullWidth = "100%";
                    noMargin = "0";
                  } 

                  return (
                    <AnswerWrap
                      className="questionContent fadeInDown"
                      style={{ opacity: 0, width: fullWidth, marginRight: noMargin }}
                      key={`question-${index}-${answerIndex}`}
                    >
                      <input
                        type={isMultipleChoice ? "checkbox" : "radio"}
                        name="question"
                        value={value}
                        checked={isChecked()}
                        onChange={onChange}
                        id={inputId}
                      />
                      <AnswerLabel
                        htmlFor={inputId}
                        dangerouslySetInnerHTML={{ __html: sanitizeHtml(label) }}
                      ></AnswerLabel>
                    </AnswerWrap>
                  );
                })}
              </FormOptions>
            </form>

            <Submit
              data-test="submit-button"
              id="submit-button"
              ref={(node) => (submitButton.current = node)}
              onClick={submit}
              disabled={
                !answers ||
                //!canSubmit ||
                (answers && !answers[index]) ||
                (isMultipleChoice && !answers[index].length)
              }
              disabledStyle={
                !answers ||
                (answers && (!answers[index] || answers[index].length === 0))
              }>
              <span dangerouslySetInnerHTML={{__html: dictionary.buttons.select}} />
            </Submit>
            
            {questions[index].explanation ? (
              <>
              <AnswerExplanation
                style={{"opacity" : !showICS ? 1 : 0 }}
                aria-hidden={!showExplanation}
                ref={(node) => (answerExplanation.current = node)}
                className="answer-explanation"
              >
                {buttonState === FAIL && (
                  <ThumbsDownContainer>
                    <img width="86px" height="86px" src="./thumbs-down.svg" alt="Thumbs down" />
                  </ThumbsDownContainer>
                )}
                {buttonState === SUCCESS && (
                  <ThumbsDownContainer >
                    <img width="86px" height="86px" src="./thumbs-up.svg" alt="Thumbs up" />
                  </ThumbsDownContainer>
                )}


                <ExplanationTitle 
                  data-test="explination-title"
                  style={{"padding": buttonState !== SUCCESS? "0 20%":"0 10%"}}>{`${
                  buttonState === SUCCESS
                  ? `${shared.right}`
                  : `${shared.wrong}`
                  } ${name}.`}</ExplanationTitle>

                <ExplanationContent
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(
                      swapName(questions[index].explanation.body, fullname)
                    ),
                  }}
                ></ExplanationContent>

                {questions[index].explanationLink && (
                  <ExplanationLink>
                    <a 
                      data-test="ics-button"
                      href="#"
                      onClick={onShowICS}
                      dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(
                        questions[index].explanationLink.body
                        ),
                    }} />
                  </ExplanationLink>
                )}

                <ButtonSecondary 
                  disabled={!showExplanation} 
                  onClick={goNext}>
                    <span dangerouslySetInnerHTML={{__html: dictionary.buttons.continue}} />
                </ButtonSecondary>

              </AnswerExplanation>

              {showICS && 
                <Statement setShowICS={setShowICS} />
              }

              </>
            ) : null}
          </>
      </Wrapper>
    </Container>
  );
}