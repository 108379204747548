import styled from "styled-components";
import { ButtonSecondary, ContentWrapper, devices } from "../../styles";

export const Wrapper = styled(ContentWrapper)`
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 652px;
  margin-top: ${(props) => props.showICS && "100px"};
  height: ${(props) => props.showICS && "776px !important"};

  b {
    font-weight: bold;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  @media ${devices.large} {
    max-width: 940px;
    //height: ${(props) => props.showICS && "833px !important"};
    height: ${(props) => props.showICS && "843px !important"};
    margin-top: ${(props) => props.showICS && "80px !important"};
  }
`;

export const QuestionPagination = styled.div`
  text-transform: uppercase !important;
  color: ${({ theme }) => theme.body} !important;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 250;
  letter-spacing: 2.88px;

  @media ${devices.large} {
    margin-bottom: 8px;
    font-size: 15px;
    line-height: 26px;

  }
`;

export const QuestionTitle = styled.h1`
  color: ${({ theme }) => theme.body} !important;
  font-size: 21px !important;
  line-height: 30px !important;
  text-align: left !important;
  text-transform: none !important;
  font-weight: 400 !important;
  font-family: FilsonProBook !important;

  @media ${devices.large} {
    font-weight: 300 !important;
    font-size: 23px !important;
    line-height: 40px !important;
  } 
`;

export const QuestionSubtitle = styled.p`  
  color: ${({ theme }) => theme.body} !important;
  font-style: normal  !important;
  font-weight: 250  !important;
  margin-block: 24px 16px !important;
  font-size: 17px !important;
  line-height: 26px !important;

  @media ${devices.large} {
    margin-top: 24px;
  }
`;


export const AnswerExplanation = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 16px;
  right: 16px;

  @media ${devices.M} {
    left: 32px;
    right: 32px;
  }

  padding-bottom: 30px;

  sup {
    font-size: 0.6em;
    vertical-align: top;
  }

  sub {
    vertical-align: bottom;
    font-size: 0.6em;
  }
  ul {
    list-style-type: disc;
    margin: 5px 5px 5px 20px;
  }
`;

export const SubmitButtonWrapper = styled.div`
position: absolute;
top: calc(100% - 90px);

left: 0;
right: 0;
display: flex;
justify-content: center;
`;

export const AnswerWrap = styled.div`
  /* margin-bottom: 16px; */
  flex-shrink: 1;
  display: flex;

  input {
    position: absolute;
    opacity: 0;
  }

  &:last-child {
    //margin-bottom: 0;
  }

  @media ${devices.M} {
    width: calc(50% - 8px);

    &:nth-child(odd) {
      /* margin-right: 8px; */
    }
  }

  @media ${devices.large}{
    width: calc(50% - 8px);
  }
`;

export const FormOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 8px;

  @media ${devices.large}{
    gap: 16px;
  }
`;

export const AnswerLabel = styled.label`
  border-radius: 8px;
  border: solid 1px #C3CDD5;
  color: #0E3859;
  padding: 16px;
  font-weight: 300;
  cursor: pointer;
  font-size: 16px;
  line-height: 30px;
  flex-grow: 1;

  @media ${devices.large}{
    font-size: 20px;
    line-height: 32px;
  }

  input:checked + & {
    color: #fff;
    background-color: #0E3859;
    box-shadow: 0 1px 3px 1px rgba(60, 64, 67, 0.15),
      0 1px 2px 0 rgba(60, 64, 67, 0.3);
  }

  input:focus + & {
    outline: 2px solid transparent;
  }

  &:hover {
    background-color: ${({ theme }) => theme.disabled};
  }



  p {
    @media ${devices.S} {
      br {
        display: none;
      }
    }
  }

`;

export const ExplanationTitle = styled.h1`
  font-size: 26px;
  line-height: 31px;
  font-weight: 900;
  color: #D60E41;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
  font-family: FilsonProBold;

  @media ${devices.M} {
    padding: 0 5%;
  }

  @media ${devices.large}{
    margin-bottom: 32px;
    font-size: 36px;
    line-height: 43px;
    padding: 0 10%;
  }
`;

export const ExplanationLink = styled.div`
  margin-bottom: 24px;
  a {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 16px 25px 16px 45px;
    background-color: #FFFFFF;
    background-repeat: no-repeat;
    border-radius: 16px;
    border: 2px solid #0E3859;
    font-style: normal;
    font-weight: 800;
    font-family: FilsonProBold;
    font-size: 18px;
    line-height: 24px;
    text-transform: uppercase;
    color: #0E3859;
    position: relative;

    &::before {
      content: url('icon_info.svg');
      position: absolute;
      left: 15px;
      top: 18px;
      display: inline-block;
    }

    &::after {
      content: url('icon_arrow.svg');
      position: absolute;
      right: 15px;
      top: 19px;
      display: inline-block;
    }

    @media ${devices.large}{
      font-weight: 800;
      font-size: 23px;
      line-height: 34px;
    }
  }
`;

export const ButtonIcon = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: absolute;
  right: 10px;
`;

export const Submit = styled(ButtonSecondary)`
  margin-top: 24px;

  @media ${devices.large}{
    margin-top: 32px;
  }
`;


export const ThumbsDownContainer = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #27323e;
  margin: 0 auto 32px;
`;

export const ExplanationContent = styled.div`
  margin-bottom: 24px;

  @media ${devices.large}{
    margin-bottom: 32px;
  }

  p {
    margin: 0px;
  }
`;