import React from "react";
import { useLocation } from "react-router";
import styled from "styled-components";
import useProgress from "../../hooks/useProgress";
import { Container, devices } from "../../styles";

export default function Progressbar({ active, questions, answers, disabled }) {
  const { progress } = useProgress(answers, questions);
  const location = useLocation();
  return (
    <Center>
      <Wrapper disabled={disabled}>
        {Object.keys(progress).map((key, index) => {
          let status = "none";

          // get status of block
          if (index === active && location.pathname.includes(`/${index}`)) {
            status = "active";
          } else if (progress[key] === "wrong") {
            status = "wrong";
          } else if (progress[key] === "right") {
            status = "right";
          }

          // pass status to styled component to get the right styling
          return (
            <Section status={status} key={key}>
              <Indicator key={`progress-${index}`} status={status}></Indicator>
              <IconContainer
                className={status}
                dangerouslySetInnerHTML={{
                  __html:
                    status === "right"
                      ? ""
                      : status === "wrong"
                      ? "&#10005;"
                      : "",
                }}
              />
            </Section>
          );
        })}
      </Wrapper>
    </Center>
  );
}

const Center = styled(Container)`
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
  height: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.light};
  box-shadow: 0 4px 8px 3px ${({ theme }) => theme.boxshadow}, 0 1px 3px 0 ${({ theme }) => theme.boxshadowAlt};
  display: flex;
  overflow: hidden;
  position: absolute;
  left: 18px;
  right: 18px;
  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
  z-index: 1;

  @media ${devices.M} {
    left: 42px;
    right: 42px;
  }

  @media ${devices.Portrait} {
    left: 0;
    right: 0;
  }
  @media ${devices.Large} {
    left: 0;
    right: 0;
  }
`;

const Indicator = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  color: white;
  background: ${({ theme, status }) => {
    if (status === "right") {
      return theme.success;
    }
    if (status === "wrong") {
      return theme.fail;
    }
    if (status === "active") {
      return "#eeeeee";
    }

    return "transparent";
  }};
  //opacity: ${(props) => props.status === "wrong" && "0.3"};
`;

const Section = styled.div`
  position: relative;
  width: 100%;
  color: white;
  border-right: 1px solid white;

  &:last-child {
    border-right: none;
  }

  &:not(:last-child) {
    ${Indicator} {
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        right: 0px;
        //top: 25%;
        border-right: ${({ status }) =>
          status !== "wrong" && status !== "right" && status !== "active"
            ? "1px solid rgb(68, 84, 100, 0.2)"
            : "none"};
      }
    }
  }
`;

const IconContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  top:-1px;

  &.right {
    content: "";
    top: 2px;
    background: url(fundamental-icon-menu-profile-tick.svg) no-repeat center 2px;
  }
`;
