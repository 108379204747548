import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router";
import styled, { keyframes } from "styled-components";

import { devices } from "../../styles.js";

export default function Background({ isHome = false, playPromo = false, showICS = false, referencesOpen = false}) {
  const location = useLocation();
  const promo = useRef(null);

  useLayoutEffect(() => {
    if (isHome) {
      var playPromise = promo.current.play();
      if (playPromise !== undefined) {
        playPromise
        .then(_ => {})
        .catch(error => {
          console.log(error);
        });
      }
    } 

  }, [location]);

  useEffect(() => {
    window.onload = () => {
      /* Event Tracking 
      Event Category: Ecommerce
      Event Action: Add to Wishlist
      Event Name: Smartphone
      Event Value: 1000 */
      window._paq.push([
        'trackEvent', 
        'PBMSIM', 
        'Page Navigation', 
        process.env.REACT_APP_IPAD === "true" ? 'IPad' : 'Touchscreen', 
        'Step 1 - Take the Next Step'
      ]);
    };
  }, []);


  return (
    <div>
     
        <BackgroundContent 
          isRestart={location.pathname.includes("/exit")} 
          showICS={showICS} 
          showReferences={referencesOpen} 
          className="background-content-intro"
        >
          <div>
            {(!showICS && !referencesOpen) && 
              <BackgroundImage
                style={{"display" : "none"}}
                id="background-image"
                alt="background"
                src={isHome ? "./background-imgs/promo.png" : "./background-imgs/Mary_door-1.png" }
                className={isHome && "background-image-home" }
            />}

            {isHome && 
              <video
                ref={promo} 
                style={{"zIndex" : "0" }}
                muted
                playsInline
                src="./videos/promo.mp4"
                type="video/mp4"
                preload="auto"
                loop
              >
                <track kind="captions" srcLang="en" label="english_captions"></track>
              </video>
            }
            
          </div>
        </BackgroundContent>
    
    </div>
  );
}

const BackgroundContent = styled.div`
  position: absolute;
  background-color: ${props => (props.showICS || props.showReferences) ? "rgba(14, 56, 89, 0.75)" : "transparent"};
  height: ${props => props.showICS ? "1080px" : "auto"};
  left: 0;
  right: 0;
  z-index: ${props => (props.showICS || props.showReferences) ? "0" : "-1"};
  height: 1024px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  transition: ${props => (props.showICS || props.showReferences) ? 'background-color 0.4s ease-in' : 'none'};

  @media ${devices.M} {
    right: 0px;
  }

  video {
    position: absolute;
    left: -475px;
  }

  @media ${devices.large} {
    height: 1024px;
    video {
      position: unset;
      left: unset;
    }
  }

  @media ${devices.touchscreen} {
    height: 1080px;
  }

  div {
    position: relative;
    height: 1024px;
    width: 100%;
    overflow: hidden;
    display: flex;
  }

  @media ${devices.touchscreen} {
    div {
      height: 1080px;
    }
  }
`;

/*const BackgroundAnimation = keyframes`
  /*0%{
    transform: translate3d(0, 0, 0);
  }
  50%{
    transform: translate3d(-100px, 0, 0);
  }
  100%{ 
    transform: translate3d(0, 0, 0);
  }
`;
  animation: ${BackgroundAnimation} 0.3s linear infinite;
*/

const BackgroundImage = styled.img`
  position: absolute;
  height: 371px;
  max-width: none;
  
  @media ${devices.S} {
    height: 255px;
    right: -10px;
  }

  @media ${devices.M} {
    height: 1024px;
    left: -935px;
    &.background-image-home {
      left: -475px;
      top: 0;
    }
  }

  @media ${devices.large} {
    height: 1024px;
    left: -425px;
    &.background-image-home {
      left: 0;
     }
  }

  @media ${devices.touchscreen} {
    height: 1080px;
  }

  @media (min-width: 932px) and (max-width: 1025px) {
    top: -122px;
    height: 593px;
  }

  @media (min-width: 2000px) {
    margin-left: auto;
    left: 0;
    right: 0;
    position: absolute;
    animation: none;
  }
`;