import React, { useEffect } from "react";
//import gsap from "gsap/gsap-core";
import styled from "styled-components";
import { useReferences } from "../../hooks/useReferences";
import { devices } from "../../styles";

export default function ReferencesModal({
  showICS = false,
  isHome,
  isOpen,
  setReferencesOpen,
  references,
  abbreviations,
  content,
}) {
  // const { content } = useReferences(references, abbreviations);

  // useEffect(() => {
  //   if (isOpen) {
  //     const menuBar = document.getElementById("menu-bar");
  //     console.log(menuBar.offsetTop, menuBar.clientHeight);

  //     // TODO: get distance from menu bar to the top of the content area -- set this as the to y value (and from y value when closing)
  //     // TODO: get the correct numbers for this section to move the popup to the top of the content container
  //     gsap.fromTo(
  //       "#references-wrapper",
  //       { autoAlpha: 0, y: 111 },
  //       { autoAlpha: 1, y: -592 }
  //     );
  //   }
  // }, [isOpen]);

  // const closeModalAnimation = () => {
  //   gsap.fromTo(
  //     "#references-wrapper",
  //     { y: -592 },
  //     { y: 111, autoAlpha: 0, onComplete: () => setReferencesOpen(false) }
  //   );
  // };

  return (
    <>
    {!isHome &&
      <Wrapper id="references-wrapper" isOpen={isOpen} showICS={showICS}>
        <TopBar>
          <TopBarBackground />
          <CloseButton
            data-test="ref-button-close"
            role="button"
            tabIndex="0"
            onKeyPress={() => setReferencesOpen(false)}
            onClick={() => setReferencesOpen(false)}
          ></CloseButton>
        </TopBar>
        <Content dangerouslySetInnerHTML={{ __html: content }}></Content>
      </Wrapper>
    }
    </>
  );
}

const Wrapper = styled.div`
  background: white;
  color: black;
  /* width: 721px; */
  margin-left: 55px;
  width: 652px;
  border-radius: 14px;
  z-index: 0;
  z-index: ${({isOpen}) => isOpen ? 0 : -1};
  position: absolute;
  bottom: 180px;
  opacity: 0;

  ${({ isOpen }) =>
    isOpen &&
    `
    animation-name: open-animation;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    `
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    animation-name: close-animation;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    `
  }

  @keyframes open-animation {
    0% {
      height: 0;
      opacity: 0;
    }
    100% {
      height: 725px;
      height: ${({showICS}) => showICS ? "740px" : "725px"};
      opacity: 1;
    }
  }
  @keyframes close-animation {
    0% {
      height: ${({showICS}) => showICS ? "740px" : "725px"};
      opacity: 1;
      z-index: 1;
    }
    100% {
      height: 0;
      opacity: 0;
      z-index: -1;
    }
  }

  @media ${devices.Portrait} {
    top: 900px;
    bottom: none;
  }
  @media ${devices.large} {
    bottom: 165px;
    width: 940px;
    margin-left: 265px;
    z-index: ${({isOpen}) => isOpen ? 0 : -1};

    @keyframes open-animation {
      0% {
        height: 0;
        opacity: 0;
      }
      100% {
        height: ${({showICS}) => showICS ? "834px" : "795px"};
        opacity: 1;
      }

      @keyframes close-animation {
        0% {
          height: ${({showICS}) => showICS ? "834px" : "795px"};
          opacity: 1;
          z-index: 1;
        }
        100% {
          height: 0;
          opacity: 0;
          z-index: -1;
        }
      }
    }
  }
`;

const TopBar = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 72px;
  width: 100%;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  position: relative;

  @media ${devices.XL} {
    height: 82px;
  }
`;

const TopBarBackground = styled.div`
  background-color: #445464;
  opacity: 0.06;
  width: 100%;
  height: 100%;
  position: absolute;
`;

const Content = styled.div`
  padding: 30px 20px;
  color: #415464;

  p {
    font-size: 18px;
    font-size: 21px;
    line-height: 31px;

    sub {
      vertical-align: bottom;
      font-size: 0.6em;
      position: relative;
      bottom: -8px;
    }
  }
  ol {
    font-size: 18px;
    font-size: 21px;
    line-height: 31px;
    color: #3B2B2B;
    list-style-position: inside;
    list-style-type: decimal;

    li  {
      font-weight: 300;
      display: list-item;
    }

    li::marker {
      font-weight: 500;
      font-family: FilsonProBold;
    }
  }

  @media ${devices.XL} {
    padding: 40px;

    p {
      font-size: 24px;
      line-height: 36px;
    }

    ol {
      font-size: 24px;
      line-height: 36px;
    }
  }
`;

const CloseButton = styled.div`
  margin-left: 12px;
  font-size: 20px;
  align-self: center;
  position: relative;
  width: 20px;
  height: 20px;
  background: inherit;
  margin-right: 20px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 2px;
    background: #415464;
    top: 9px;
    right: 1px;
  }

  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }

  @media ${devices.XL} {
    width: 26px;
    height: 26px;
    margin-right: 28px;

    &:before,
    &:after {
      width: 26px;
    }
  }
`;
