import React, { useState } from "react";
import styled from "styled-components";

export default function Login({ }) {

  const [isOpen, setIsOpen] = useState(true);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (event.srcElement[0].value == "user2022" &&  event.srcElement[1].value == "Gz2I9k&Gz25q6h") {
        setIsOpen(false);
        console.log(isOpen);
    } else {
        setIsOpen(true);
        console.log(isOpen);
    }
  };

  return (
    <Wrapper style={{"display" : isOpen ? "block" : "none" }}>
	    <form onSubmit={() => handleSubmit(event)}>
            <p><span className="formtext">Login</span></p>
            <p><input 
                    type="text" 
                    placeholder="Username" 
                    required 
                />
            </p>
            <p>
                <input 
                    type="text" 
                    placeholder="Password" 
                    required 
                    />
                </p>
            <button>Go!</button>
            
    	</form>
    </Wrapper>
    )
};

const Wrapper = styled.div`
    color: white;
    position: absolute;
    background-color: white;
    height: 100%;
    width: 100%;
    z-index: 10000;
    display: block;
    font-family: ${({ theme }) => theme.footerFont};
    padding: 20px;
    //flex: 1 1 0;
    
    box-sizing: border-box;
    z-index: 1;
`;

const Content = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: 1165px;
  padding: 10px 0px;

  p,
  ol {
    &:first-child {
      //border-top: solid 1px #424b5a;
    }
    padding-top: 24px;
    font-size: 10px;

    sub {
      vertical-align: bottom;
      font-size: 0.7em;
    }
  }
  p {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  ol {
    counter-reset: li;
    padding-top: 0;

    li {
      color: #3B2B2B;
      line-height: 20px;
      padding-left: 1em;
      text-indent: 0;
      font-size: 12px;
      position: relative;
      &:before {
        color: #3B2B2B;
        font-size: 12px;
        position: absolute;
        display: inline-block;
        top: 0;
        content: counter(li) ".";
        counter-increment: li;
        //font-weight: bold;
        left: 0;
      }
      a {
        color: #3B2B2B;
      }
    }
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
`;

const Button = styled.button`
  background-color: green;
  align-items: center;
  color: #3B2B2B;
  background: inherit;
  border: none;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: flex-start;
  line-height: 20px;
  margin-bottom: 15px;
  padding: 10px 0 0;
  letter-spacing: -0.5px;
`;

const Icon = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 6px;

  &::after {
    content: url('./chevron-f1.svg');
    position: absolute;
    width: 16px;
    height: 17px;
    //top: ${(props) => (props.isOpen ? "calc(50% - 4px)" : "calc(50% + 4px)")};
  }

  &::after {
    transform: ${(props) =>
      props.isOpen ? "rotate(0deg)" : "rotate(180deg)"};
  }
`;
