import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "core-js";
import smoothscroll from "smoothscroll-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./reset.css";
import "./fonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter as Router } from "react-router-dom";
//import TagManager from "react-gtm-module";

// polyfill for smooth scrolling on safari / IE
smoothscroll.polyfill();


//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
//serviceWorkerRegistration.register();

//Tag manager key
/*const tagManagerArgs = {
  gtmId: "GTM-TLXJL8F",
};*/

// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
