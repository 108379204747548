import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';
import {gsap} from 'gsap';
import { devices } from "../../styles.js";


export default function AnimatedArrow(text) {

  let wrapper = useRef();
  let arrowpbk = useRef(); 
  let textItem = useRef(null);

  useEffect(() => {

    let width = wrapper.current.clientWidth;
    const tl = gsap.timeline({repeat: -1, repeatDelay: 10, yoyo: false,  defaults: {duration: 0.5}});
    const elements = [ 
      arrowpbk.current
    ];
    const text = [ 
      textItem.current
    ];
    const arrowAnimation = tl.fromTo(
      elements,
      { autoAlpha: 0, x: 0 },
      { autoAlpha: 1, x: width === 331 ? 272 : 222, duration: 0.5}
    );
    const textAnimation = tl.fromTo(
      text,
      { autoAlpha: 0 },
      { autoAlpha: 1, duration: 1 }
    );
    return () => {
      arrowAnimation.kill();
      textAnimation.kill();
    };
  }, []);

  function changeImg(e) {
    arrowpbk.current.classList.add("alt");
    textItem.current.classList.add("alt");
  }

  function revertImg(e) {
    arrowpbk.current.classList.remove("alt");
    textItem.current.classList.remove("alt");
  }
  return (
      <span style={{"background": "none", "paddingRight" : "0",  "display": "inline"}}>
        <Wrapper
          ref={wrapper} 
          onMouseEnter={changeImg}
          onMouseLeave={revertImg}
          >
            <TextItem dangerouslySetInnerHTML={{__html: text.text }} ref={textItem} />
            <Arrowpbk ref={arrowpbk} />
        </Wrapper>
      </span>
  )}

const Wrapper = styled.div`
  display: block;
  background : none;
  margin-top: -12px;
  &:hover,
  &:active,
  &:focus { 
    div:last-child {
        background-image: url("icon_btn-arrow_f2.svg") !important;
    }
  }
  @media ${devices.large} {
    margin-top: -16px;
  }
`;

const Arrowpbk = styled.div`
  background-image: url(icon_btn-arrow_f1.svg);
  background-repeat: no-repeat;
  background-position: left -7px;
  width: 66px;
  height: 43px;
  position: absolute;
  opacity: 0;
  margin-top: -2px;
`;

const TextItem = styled.div`
  display: block;
  position: absolute;
  font-weight: 500;
  font-family: FilsonProBold;

`;