import styled from "styled-components";
import { Container, ContentWrapper, devices } from "../../styles";

export const IntroContainer = styled(Container)`
  margin: 0px;
  
`;

export const IntroWrapper = styled(ContentWrapper)`
  background-color: transparent;
  padding: 0px;
  box-shadow: none;
  margin-top: 230px;
  margin-left: 55px;
  max-width: 653px;

  @media ${devices.large} {
    margin-top: 208px;
    margin-left: 160px;
    max-width: 940px;
  }

  &::before {
    content: "";
    position: fixed;
    bottom: 0;

    @media ${devices.large} {
      position: absolute;
      height: 100vh;
    }

    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(
        89.75deg,
        rgba(14, 56, 89, 0.25) 15.32%,
        rgba(14, 56, 89, 0.2075) 55.05%,
        rgba(14, 56, 89, 0) 63.66%,
        rgba(14, 56, 89, 0) 70.41%
      ),
      linear-gradient(
        86.92deg,
        rgba(14, 56, 89, 0.25) 53.7%,
        rgba(14, 56, 89, 0.015) 67.56%,
        rgba(14, 56, 89, 0) 81.76%
      ),
      linear-gradient(
        72.41deg,
        rgba(14, 56, 89, 0.25) 44.75%,
        rgba(14, 56, 89, 0) 50.5%,
        rgba(14, 56, 89, 0) 57%
      ),
      radial-gradient(
          42.04% 199.36% at 64.1% 42.04%,
          rgba(14, 56, 89, 0) 0%,
          rgba(14, 56, 89, 0.25) 67.28%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      linear-gradient(
        92.03deg,
        rgba(14, 56, 89, 0.55) 2.25%,
        rgba(14, 56, 89, 0.231) 52%,
        rgba(14, 56, 89, 0) 60.73%,
        rgba(14, 56, 89, 0) 90.41%
      )
      /*url(Mary_final.jpg);*/
  }
  text-align: center;

  h1,
  h2 {
    color: #ffffff;
    /*font-family: "orgovan-rounded-web";*/
    width: 100%;
    text-align: center;
    letter-spacing: 0.01em;
    text-shadow: 0px 3.63214px 3.63214px rgba(14, 56, 89, 0.25);
    font-weight: 400;
  }

  h1 {
    font-size: 48px;
    line-height: 58px;
  }
  h2 {
    font-size: 58px;
    line-height: 70px;
    min-width: 653px !important;
    margin-bottom: 24px;
  }
  img.heading2 {
    margin-bottom: 23px;
    position: relative;
    max-width: 653px;
  }

  @media ${devices.large}{
    h1 {
      font-size: 64px;
      line-height: 77px;
    }
    h2 {
      font-size: 78px;
      line-height: 94px;
      margin-bottom: 40px;
      min-width: 940px !important;
    }
    img.heading2 {
      margin-bottom: 40px;
      max-width: none;
    }
  }

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
      line-height: 51px;
    text-align: center;
    letter-spacing: 1px;
    color: #ffffff;
    text-shadow: -8px -2px 6px rgba(14, 56, 89, 0.15),
      3px 1px 4px rgba(14, 56, 89, 0.25), 5px 1px 5px rgba(14, 56, 89, 0.25);

    @media ${devices.large}{
      font-size: 48px;
      line-height: 62px;
    }

    &:first-of-type {
      font-family: FilsonProBold;
    }

    &:last-of-type {
      font-weight: 250;
      margin-top: 24px;
      font-size: 22px;
      line-height: 33px;
      max-width: 640px;
      margin-inline: auto;

      @media ${devices.large}{
        margin-top: 40px;
        font-size: 24px;
        line-height: 36px;
        max-width: unset;
      }
    }
  }

  button {
    background-color: white;
    color: #0e3859;

    box-shadow: 0px 1px 2px rgba(60, 64, 67, 0.3), 0px 1px 3px 1px rgba(60, 64, 67, 0.15);
    border: 1px solid transparent;

    @media ${devices.large}{
      margin-top: 50px;
      padding-left: 50px;
      padding-right: 40px;
    }
  }
  

  hr {
    background-color: ${({ theme }) => theme.border};
    border: none;
    height: 1px;
    width: 40px;
    margin: 15px auto;

    @media ${devices.M} {
      margin: 20px auto;
    }
  }

  video {
    position: absolute;
    right: 0;
    height: 255px;
    top: 0;
  }
`;

export const JobCode = styled.div`
  position: relative
  width: 100%;
  margin: 0 auto;
  max-width: 1165px;
  margin-top: 4px;
  label {
    position: fixed;
    bottom: 2%;
    right: 2%;
    font-style: normal;
    font-weight: 425;
    font-size: 12px;
    line-height: 21px;
    padding: 24px 0px;
    color: #3B2B2B;
    color: white;
  }
`;
