export const dictionary = {
  intro:{
    heading: `PBM`,
    heading2: "Anaemia Escape Room",
    paragraph1: `Can your knowledge of PBM get Mary home without delay?`,
    paragraph2: `Mary is scheduled to have elective surgery in 5 weeks. She needs to get home as soon as she can to her unwell husband.`,
    paragraph3: `Your knowledge can fast-forward Mary's exit – are you up for the challenge?`,
    cta: `Take the challenge`
  },
  start:{
    title: 'Start again',
    heading: `To support her hospital exit, you must make 10 decisions ahead of [NAME]'s surgery — every decision you make in the pre-operative stage will impact Mary's outcomes and how quickly she can go home.`,
    paragraph1: `See each outcome and receive feedback on your diagnosis and treatment decisions.`,
    cta: `Continue`,
    restartPara : 'Are you sure you want to leave?',
  },
  questions: {
    treat: `select`
  },
  resultsPage: {
    youScored: `You scored`,
    outOf: "out of",
    scale: "0&#8211;3 Low; 4&#8211;6 Intermediate; 7&#8211;10 High.",
    improveScore: "Improve your score for next time",
    share: "Share your score and challenge your peers.",
    failure : `You did not successfully help [NAME] exit the hospital.`,
    negative: "Challenge a peer to see if they can do better.",
    positive: "Challenge a peer to see if they can do better.",
    // close: `You were close but didn’t quite manage to help [NAME] exit the hospital quickly enough.`,
    // faster:`You helped [NAME] exit the hospital but it could have been faster!`,
    success: `Challenge a peer to see if they can match your score.`,
    // beatScore: `I just took the Anaemia Escape Room challenge – can you beat my score? Test your knowledge in this patient simulation at https://pbmirondeficiency.com`,
    challenge: "Challenge a peer to see if they can do better.",
    qrCode: "Scan the QR code with your phone camera<br/> to learn more about PBM:",
  },
  footnotes: {
    footnotesAbbreviationsReference: "Footnote, abbreviations & reference",
    footnotesAbbreviationsReferences: "Footnote, abbreviations & references",
    footnotesAbbreviationReference: "Footnote, abbreviation & reference",
    footnotesAbbreviationReferences: "Footnote, abbreviation & references",
    abbreviation: `Abbreviation`,
    abbreviations: `Abbreviations`,
    abbreviationReference: "Abbreviation & reference",
    abbreviationReferences: "Abbreviation & references",
    abbreviationsReference: "Abbreviations & reference",
    abbreviationsReferences: "Abbreviations & references",
    footnoteAndAbbreviation : 'Footnote and abbreviation',
    footnotesAbbreviation: 'Footnotes & abbreviation',
    footnotesAbbreviations: 'Footnotes & abbreviations',
    footnoteReferences: 'Footnote & references',
    footnotesReferences: 'Footnotes & references',
    reference: `Reference`,
    references: `References`,
    footnoteAndReference : `Footnote and reference`,
  },
  buttons: {
    restart: `Restart`,
    restartWidth: `100px`,
    back: `Back`,
    giveUp: `Yes, I give up!`,
    quit: `Quit`,
    never: `Never!`,
    continue: `Continue`,
    select: `Select`,
    signUp: `Sign up`,
    startAgain: `Start Again`,
    watchSeminars: "WATCH PBM WEBINARS",
    watchSeminarsLink: "https://pbmirondeficiency.com/webinars",
  },
  pagination: {
    question: "Question",
    of: "of 9",
  },
  timeout: {
    heading: "Mary still needs your help to get home",
    para: "What would you like to do?",
  },
  modal : {
    children: "<p><strong>The PBM – Anaemia Escape Room is a learning tool.</strong> The patient case used is a fictive patient case assembled to reflect a specific clinical situation. <strong>Any resemblance to reality is purely coincidental.</strong></p><p>The clinical situation presented here might be different from one patient to another. Therefore, <strong>clinical judgment should individually apply.</strong></p><p><strong>The PBM – Anaemia Escape Room is intended for HCPs and hospital staff only.</strong></p>",
    cancel: "Cancel",
    continue: "Continue",
  },
  stamp: `HQ-NA-2400006 February 2024`,
  stampStandalone: `HQ-NA-2400006 February 2024`
};