import styled from "styled-components";
import { ContentWrapper, devices } from "../../styles";

export const ResultsWrapper = styled(ContentWrapper)`
  text-align: center;
  padding: 32px;
  max-width: 652px;
  
  @media ${devices.large}{
    padding: 48px;
    margin-top: 120px;
    width: 100%;
    max-width: 940px;
  }

  h1 {
    color: ${({ theme }) => theme.primary};
    display: inline-block;
    font-size: 22px;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    text-transform: uppercase;

    @media ${devices.M} {
      font-size: 26px;
      line-height: 30px;
    }

    @media ${devices.L} {
      max-width: 442px;
    }
  }

  h2 {
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
  }

  hr {
    width: 80px;
    height: 2px;
    background-color: #64c3e1;
    border: none;
  }

  p {
    margin: 0px;
    margin-inline: 20px;
    
    &:first-of-type {
      font-size: 21px;
      line-height: 30px;
    }
  }
  
  @media ${devices.large}{
    p {
      &:first-of-type {
        font-size: 30px;
        line-height: 42px;
      }
    }
  }
`;

export const ScoreCard = styled.div`
  color: ${({ theme }) => theme.secondary};
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 30px;

  @media ${devices.large}{
    font-size: 30px;
    line-height: 42px;
  }
`;

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${devices.large}{
    gap: 24px;
  }
`;

export const QRContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${devices.large}{
    gap: 18px;
  }
`;

export const QRCodeHeading = styled("p")`
  font-size: 16px !important;
  line-height: 30px !important;

  @media ${devices.large}{
    font-size: 23px !important;
    line-height: 40px !important;
  }
`;

export const QRCode = styled("div")`
  content: url("./qrcode.svg");
  margin: auto;
  width: 156px;
  height: 156px;
`;

export const QRCodeLink = styled("p")`
  font-weight: 700;
  font-size: 16px !important;
  line-height: 30px !important;

  strong {
    font-weight: 500;
    font-family: FilsonProMedium;
  }

  @media ${devices.large}{
    font-size: 23px !important;
    line-height: 40px !important;
  }
`;
