import React from "react";
import styled from "styled-components";
import { devices } from "../../styles";

export default function InternalMessage() {
  return (
    <Message>
        <p>INTERNAL USE ONLY – DO NOT SHARE EXTERNALLY</p>
    </Message>
  )
}

const Message = styled.div`
  background-color: rgb(214, 14, 65);
  position: fixed;
  bottom: 0;
  color: white;
  width: 100%;
  z-index: 1000;
  padding: 10px;
  text-align: center;
  font-family: FilsonProBook;

  @media ${devices.L} {
    position: absolute;
    bottom: inherit;
    top: 0;
  }
`;