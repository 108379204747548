import { createContext, useContext } from "react";
import {
  q1,
  q2,
  q3,
  q4,
  q5,
  q6,
  q7,
  q8,
  q9
} from "./questions";

const patient5 = {
  name: "Mary",
  fullname: "Mary",
  profilePic: "/patient-5-img@3x.png",
  details: {
    title: "Mary, **​",
    stats: [
      {
        tabLabel: "Before NVD",
        subTitle: "Mary",
        rows: [
          { key: "Key", value: "value" },
          { key: "Key", value: "value​" },
          { key: "Key ", value: "value" },
          { key: "key", value: "value" },
          { key: "key", value: "value" },
          { key: "key", value: ["value"]}
        ],
      },
      {
        tabLabel: "After NVD",
        subTitle: "Mary",
        rows: [
          { key: "Key", value: "value" },
          { key: "Key", value: "value​" },
          { key: "Key ", value: "value" },
          { key: "key", value: "value" },
          { key: "key", value: "value" }
        ],
      },
    ],
  },
  questions: [q1, q2, q3, q4, q5, q6, q7, q8, q9],
};

const PATIENTS = [patient5];

const PatientsContext = createContext(PATIENTS);

export const usePatients = () => {
  return useContext(PatientsContext);
};
