import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { q8 } from "../../contexts/questions";
import { dictionary } from "../../contexts/site";

export default function Footnotes({ references, abbreviations, showICS, showStandalone }) {

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAbbreviation, setIsOpenAbbreviation] = useState(false);
  const docReferences = new DOMParser().parseFromString(
    references,
    "text/html"
  );
  const docAbbreviations = new DOMParser().parseFromString(
    abbreviations,
    "text/html"
  );
  const listReferences = docReferences.querySelectorAll("li");
  //const listAbbreviations = docAbbreviations.querySelectorAll('p');
  const listAbbreviations2 = docAbbreviations.querySelectorAll("p")[0]
    ? docAbbreviations.querySelectorAll("p")[0].innerHTML.split(";")
    : 0;
  const location = useLocation();

  useEffect(() => {
    if (!!references) {
      setIsOpen(false);
    }
    if (!!abbreviations) {
      setIsOpenAbbreviation(false);
    }
  }, [references, abbreviations, setIsOpen, setIsOpenAbbreviation]);

  return (
    <Wrapper>

      {!!abbreviations 
      && abbreviations[0] 
      && abbreviations[0].length 
      &&  (
        <Content>
          <ButtonContainer>
            <Button onClick={() => setIsOpenAbbreviation(!isOpenAbbreviation)}>
              { showICS  
                ? dictionary.footnotes.footnoteAndAbbreviation 
                : dictionary.footnotes.abbreviation 
              } 
              {(listAbbreviations2.length > 1 ? "s" : "")}
              <Icon isOpen={isOpenAbbreviation} />
            </Button>
          </ButtonContainer>
          {isOpenAbbreviation && !showICS && (
            <div dangerouslySetInnerHTML={{__html: abbreviations}} />
          )}

          {isOpenAbbreviation && showICS && (
            <div dangerouslySetInnerHTML={{__html: q8.abbreviationICS}} />
          )}
        </Content>
      )}

      {!!references && references[0] && references[0].length && (
        <Content>
          <ButtonContainer>
            <Button onClick={() => setIsOpen(!isOpen)}>
            {location.pathname.includes("2") 
              ? dictionary.footnotes.footnoteAndReference 
              : dictionary.footnotes.reference
            } 
            {(listReferences.length > 1 || showICS ? "s" : "")  }
               <Icon isOpen={isOpen} />
            </Button>
          </ButtonContainer>
          
          {isOpen && !showICS && (
            <div dangerouslySetInnerHTML={{__html: references}} />
          )}

          {isOpen && showICS && (
            <div dangerouslySetInnerHTML={{__html: q8.referenceICS}} />
          )}

        </Content>
      )}
      
      {showStandalone && location.pathname === '/' && 
        <JobCode>
          <label dangerouslySetInnerHTML={{__html: dictionary.stampStandalone}} />
        </JobCode>
      }

    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.footerFont};
  padding: 20px;
  //flex: 1 1 0;

  box-sizing: border-box;
  z-index: 1;
`;

const Content = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: 1390px;
  padding: 10px 0px;

  p,
  ol {
    &:first-child {
      //border-top: solid 1px #424b5a;
    }
    padding-top: 24px;
    font-size: 10px;

    sub {
      vertical-align: bottom;
      font-size: 0.7em;
    }
  }
  p {
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-top: 0;
  }
  ol {
    counter-reset: li;
    padding-top: 0;

    li {
      color: #3b2b2b;
      line-height: 20px;
      padding-left: 1em;
      text-indent: 0;
      font-size: 13px;
      position: relative;
      &:before {
        color: #3b2b2b;
        font-size: 12px;
        position: absolute;
        display: inline-block;
        top: 0;
        content: counter(li) ".";
        counter-increment: li;
        //font-weight: bold;
        left: 0;
      }
      a {
        color: #3b2b2b;
      }
    }
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
`;

const Button = styled.button`
  align-items: center;
  color: #3b2b2b;
  background: inherit;
  border: none;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: flex-start;
  line-height: 20px;
  margin-bottom: 15px;
  padding: 10px 0 0;
  letter-spacing: -0.5px;
`;

const Icon = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  margin-left: 6px;

  &::after {
    content: url("./chevron-f1.svg");
    position: absolute;
    width: 16px;
    height: 17px;
    //top: ${(props) => (props.isOpen ? "calc(50% - 4px)" : "calc(50% + 4px)")};
  }

  &::after {
    transform: ${(props) => (props.isOpen ? "rotate(0deg)" : "rotate(180deg)")};
  }
`;
