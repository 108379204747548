import React, { useEffect } from "react";
import { useHistory } from "react-router";
import styled, { keyframes } from "styled-components";
import { ButtonPrimary, ButtonSecondary, devices } from "../../styles";
import MaryProfile from "./mary_profile.png";
import { dictionary } from "../../contexts/site";

const timerLength = 1000 * 30;

export default function Timeout({ name = "Mary", setShow= false, setPlayPromo = false, reset }) {
  const history = useHistory();

  // if timeout finishes, redirect to homepage and set isIdle to false
  useEffect(() => {
    const timeout = setTimeout(() => {
      reset();
      setPlayPromo(true);
      setShow(false);
      history.push("/");
    }, timerLength);

    return () => clearTimeout(timeout);
  }, [reset]);

  return (
    <Wrapper>
      <Content>
        <CountdownWrapper>
          <div id="countdown">
            <ImageWrapper>
              <img alt="profile of Mary" src={MaryProfile} />
            </ImageWrapper>
            <Circle>
              <circle r="47%" cx="50%" cy="50%"></circle>
            </Circle>
            <OrangeBorder>
              <circle r="47%" cx="50%" cy="50%"></circle>
            </OrangeBorder>
          </div>
        </CountdownWrapper>
        <h1 dangerouslySetInnerHTML={{__html: dictionary.timeout.heading}} />
        <p dangerouslySetInnerHTML={{__html: dictionary.timeout.para}} />
        <hr className="fadeIn" />
        <ContinueButton onClick={() => reset()}>
          {dictionary.buttons.continue}

          <svg width="66" height="43" viewBox="0 0 66 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.0115 18.2245C17.0115 18.5218 16.7253 18.7628 16.3718 18.7628C16.0187 18.7628 15.7324 18.5218 15.7324 18.2245C15.7324 17.9275 16.0187 17.6865 16.3718 17.6865C16.7253 17.6865 17.0115 17.9275 17.0115 18.2245Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.9717 9.21144C32.6271 9.6769 32.2324 12.9826 32.3947 14.3922C32.6536 16.6401 33.1387 18.99 32.7777 21.3077C32.5993 22.4547 32.4173 23.6068 32.6813 24.7681C32.961 25.9975 32.6993 26.8351 32.616 28.0604C32.5268 29.3667 32.6312 30.7952 33.0909 31.9971C33.3914 32.7831 33.2133 35.0434 33.8617 35.7019C34.7815 36.636 37.4044 34.9763 38.3865 34.513C39.6306 33.9263 40.8254 33.4427 41.991 32.7861C42.9001 32.2745 44.1555 31.7604 44.9646 31.095C46.2071 30.0725 48.2063 29.5717 49.708 28.8458C52.3661 27.5607 55.2518 26.4966 57.1348 24.8759C57.939 24.1834 60.4999 23.1674 60.4999 22.1836C60.4999 21.4036 58.7298 20.4953 57.8207 19.9196C55.7472 18.6063 51.8859 17.4765 49.7083 16.32C48.5737 15.7177 46.1694 14.7217 45.0896 14.0129C43.8257 13.1832 41.6203 12.3163 40.3053 11.5886C38.4403 10.5568 35.9241 8.53602 33.9717 9.21144Z" fill="#B02933"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M7.30537 9.21151C5.90577 9.67696 5.49499 12.9827 5.66385 14.3923C5.93334 16.6402 5.93119 16.5764 5.55547 18.8942C5.36975 20.0411 5.6874 23.6069 5.96224 24.7682C6.25366 25.9975 5.98124 26.8352 5.89426 28.0604C5.80167 29.3668 5.38527 31.7678 5.86402 32.9696C6.17686 33.7555 6.51619 35.0434 7.1911 35.7019C8.14887 36.6361 10.8788 34.9761 11.901 34.5131C13.196 33.9264 14.4393 33.4425 15.6529 32.7859C16.5992 32.2743 17.9059 31.7604 18.7481 31.0948C20.0414 30.0723 22.1223 29.5717 23.6854 28.8456C26.4523 27.5605 29.4559 26.4964 31.4156 24.8755C32.253 24.1832 34.9186 23.1672 34.9186 22.1832C34.9186 21.4034 33.0761 20.4949 32.1299 19.9194C29.9713 18.6061 25.9524 17.4763 23.6854 16.3201C22.5048 15.7175 20.0018 14.7215 18.8779 14.0127C17.562 13.1833 15.2665 12.3164 13.8979 11.5885C11.9567 10.5569 9.33732 8.53587 7.30537 9.21151Z" fill="#B02933"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M59.1336 20.8977C58.7816 20.4294 58.3023 20.1129 57.7234 19.9238C57.2269 19.2494 56.2734 18.7137 55.5039 18.2979C54.1192 17.5496 51.087 16.4644 49.685 15.74C47.7823 14.7565 44.9505 13.5365 43.0599 12.53C42.0572 11.9958 40.5179 11.3603 39.5138 10.8248C38.727 10.405 34.4635 7.0775 32.9531 8.59094C32.4628 9.08224 31.6076 9.89942 32.5971 12.7942C32.8182 13.4413 33.2712 15.1255 33.6757 18.1602C33.8486 19.4578 32.9839 19.8246 30.1425 18.099C28.8103 17.2901 25.7256 16.2655 24.3241 15.541C22.4209 14.5578 19.5894 13.3376 17.6988 12.3313C16.6958 11.7969 15.1568 11.1614 14.1524 10.6259C13.3659 10.2061 9.10217 6.87857 7.59204 8.39201C7.10138 8.88353 6.24625 9.70049 7.23578 12.5953C7.69886 13.9503 9.92762 23.6854 8.27143 28.6223C7.87514 29.8031 7.74764 32.1498 8.50946 32.4878C9.35956 32.8651 11.8688 32.6932 16.0766 31.1731C16.9023 30.8749 17.1568 30.7068 18.0448 30.1511C19.4881 29.2477 21.3871 28.5816 22.9354 27.8335C25.676 26.5089 28.651 25.4128 30.5924 23.7424C30.9725 23.4152 31.8743 22.9847 32.5341 22.4102C33.1095 21.9091 34.0956 22.1076 34.1698 22.6264C34.3925 24.1825 34.0943 27.445 33.6325 28.821C33.2365 30.0018 33.5747 31.7808 34.1261 32.2824C34.6777 32.7837 36.5298 33.3215 41.3492 31.3921C42.3718 30.9829 42.3572 30.8443 43.4059 30.3501C44.9815 29.6078 46.7479 28.7805 48.2965 28.0322C51.0371 26.7078 54.0123 25.6117 55.9535 23.9411C56.7829 23.2276 59.7671 21.7406 59.1336 20.8977Z" fill="#D60D40"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M35.7386 31.9789C35.2277 31.8805 35.2365 31.3195 35.2553 30.9567C35.2836 30.4082 35.0349 30.579 34.9873 30.9066C34.9155 31.403 34.8244 32.082 35.1509 32.4473C35.6627 33.0197 36.2629 32.6697 36.1918 32.3743C36.1426 32.1698 35.9484 32.0194 35.7386 31.9789Z" fill="#F2E0E3"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M37.4256 31.5706C36.9836 31.703 36.5228 31.7165 36.3098 31.5876C36.0507 31.4312 36.2563 31.1238 36.7686 30.9011C37.5122 30.5781 38.2859 30.1468 39.0679 29.6194C39.0679 29.6194 40.4461 28.9255 41.2426 28.5708C41.6822 28.3751 42.4445 27.9721 42.5767 27.8941C43.0825 27.5898 43.6875 27.3313 44.3818 27.0328C45.1182 26.7166 45.5456 26.3994 45.9691 26.1024C47.0383 25.3516 48.5914 24.7509 49.5966 24.4647C50.4616 24.2181 54.4069 22.6167 54.9132 22.2407C55.2976 21.9199 55.9361 21.9076 56.3447 22.2087C56.7536 22.5098 56.7696 23.0168 56.3855 23.3373C55.5989 23.9937 51.0487 25.745 50.2905 25.9609C49.2249 26.2645 48.0051 26.8106 47.3241 27.2885C46.7265 27.7077 45.8612 28.0796 45.0241 28.4392C44.4273 28.6957 44.006 28.8121 43.6233 29.0424C43.4017 29.1756 42.83 29.4055 42.0798 29.7395C41.5696 29.9667 40.5884 30.3959 40.5884 30.3959C39.4939 31.0374 38.6719 31.0489 37.707 31.4683C37.6157 31.5078 37.5211 31.5422 37.4256 31.5706Z" fill="#DE6E6B"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M57.9417 21.453C57.9417 21.7504 57.6554 21.9913 57.302 21.9913C56.9488 21.9913 56.6626 21.7504 56.6626 21.453C56.6626 21.156 56.9488 20.915 57.302 20.915C57.6554 20.915 57.9417 21.156 57.9417 21.453Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M37.0053 10.5655C42.2979 14.2058 52.7943 17.7406 54.3322 18.3952C57.2842 19.651 57.3437 20.3819 55.0097 19.3446C52.676 18.3071 40.194 13.4573 36.7638 10.7208C35.7142 9.88357 36.3204 10.0945 37.0053 10.5655Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M12.6755 11.4755C18.1458 14.3506 26.1954 16.9071 27.5158 17.434C30.412 18.5897 30.4705 19.2623 28.1808 18.3077C26.2441 17.5002 17.1854 14.1833 12.4013 11.6558C11.5285 11.1947 11.8939 11.0648 12.6755 11.4755Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M33.9426 21.9926C33.2757 21.9437 29.4956 23.1656 28.6744 23.4287C27.7203 23.7342 24.6518 25.1672 23.2872 25.4851C21.3304 25.9405 18.3813 28.1546 17.964 28.3635C17.2077 28.7419 15.8992 29.4819 15.8992 29.4819C15.1566 30.0448 14.4223 30.5048 13.7163 30.8494C13.2299 31.0867 13.0347 31.4149 13.2807 31.5817C13.4829 31.719 13.9204 31.7048 14.34 31.5634C14.4307 31.533 16.3037 30.9948 17.3429 30.3103C17.3429 30.3103 18.2744 29.8526 18.7588 29.61C19.471 29.2539 20.9875 28.4965 21.5541 28.2231C22.3489 27.8395 23.1704 27.4425 23.7378 26.9954C24.3843 26.4855 25.5424 25.9032 26.5541 25.5794C27.1089 25.4017 29.8021 24.251 31.35 23.4121L31.4226 23.418C32.1021 22.98 32.7049 22.9514 33.545 22.7466C33.9552 22.6464 34.5168 22.5398 34.9598 22.5942C35.4439 22.6536 35.8882 22.8479 36.1976 23.1686C36.065 22.2778 35.4017 22.0996 33.9426 21.9926Z" fill="#DE6E6B"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M32.9564 20.2311C33.4668 19.814 33.0719 18.7772 33.335 18.7628C33.5984 18.7481 33.8887 20.2665 33.2769 20.5937C32.7829 20.8581 32.4049 21.0166 31.8589 20.8414C31.0045 20.5673 30.8727 19.9547 31.3736 20.1515C31.8742 20.3482 32.6016 20.5212 32.9564 20.2311Z" fill="#DE6E6B"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M8.9667 31.152C9.52681 31.4993 10.5378 30.9257 10.6136 31.1499C10.6893 31.3742 9.14234 31.9654 8.6547 31.5101C8.26083 31.1425 8.00581 30.8516 8.06721 30.3406C8.16319 29.5407 8.7836 29.2896 8.68928 29.7662C8.59468 30.243 8.57753 30.9101 8.9667 31.152Z" fill="#F2E0E3"/>
          </svg>

        </ContinueButton>
        <QuitButton
          onClick={() => {
            reset();
            setShow(false);
            history.push("/");
          }}
        >
          {dictionary.buttons.quit}
        </QuitButton>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  z-Index: 101;
  position: fixed;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0e3859;

  * {
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }
`;

const Content = styled.div`
  border-radius: 8px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 541px;
  width: 972px;
  position: relative;
  padding: 115px 50px 50px;
  text-align: center;

  h1,
  p {
    max-width: 588px;
  }

  h1 {
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 38px;
  }

  p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 26px;
  }

  hr {
    background-color: #64C3E1;
    border: none;
    height: 1px;
    width: 80px;
    margin: 32px auto;
  }

  button {
    min-width: 350px;
    font-size: 16px;
    letter-spacing: 0.6px;
    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &:active {
      svg circle {
        fill: #fff;
      }
    }
  }

  @media ${devices.XL} {
    width: 1002px;
    padding-top: 85px;
    padding-bottom: 105px;

    h1,
    p {
      max-width: 558px;
    }
    h1 {
      font-size: 38px;
      line-height: 52px;
      margin-bottom: 25px;
    }
    p {
      margin-bottom: 0;
      font-size: 24px;
      line-height: 36px;
    }

    button {
      //width: 552px;
      width: 426px;
      font-size: 24px;
      letter-spacing: 1.2px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
`;

const CountdownWrapper = styled.div`
  /* background: black; */
  width: 193px;
  height: 193px;
  position: absolute;
  top: calc(-50% + 100px);

  @media ${devices.XL} {
    /* width: 302px; */
    /* height: 302px; */
    position: absolute;
    top: calc(25% - 15px);
    left: calc(-151px);
    top: calc(25%);
    left: calc(-168px);
    height: 100%;
    width: 100%;
    height: 336px;
    width: 336px;
    /* left: 0px; */

    /* img {
      height: 302px;
      width: 302px;
    } */
  }
`;

const ImageWrapper = styled.div`
  color: white;
  /* display: inline-block; */
  /* line-height: 200px; */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 193px;
    width: 193px;
  }

  @media ${devices.XL} {
    img {
      height: 320px;
      width: 320px;
      /* height: 328px;
      width: 328px; */
    }
  }
`;

const countdown = keyframes`
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 1010px;
  }
`;
const countdownIpad = keyframes`
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 580px;
  }
`;

const Circle = styled.svg`
  position: absolute;
  top: -3px;
  right: 0;
  width: 193px;
  height: 193px;
  transform: rotateY(-180deg) rotateZ(-90deg);
  /* width: 100%;
  height: 100%; */

  circle {
    stroke-dasharray: 580px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 10px;
    stroke: #F8F8F8;
    fill: none;
  }
  @media ${devices.XL} {
    width: 336px;
    height: 336px;

    circle {
      stroke-dasharray: 1010px;
      stroke-width: 17px;
    }
  }
`;

const OrangeBorder = styled(Circle)`
  circle {
    stroke: #d60e41;
    animation: ${countdownIpad} 30s linear forwards;
  }
  @media ${devices.XL} {
    circle {
      animation: ${countdown} 31s linear forwards;
    }
  }
`;

const ContinueButton = styled(ButtonSecondary)`
  border: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    top: 0px;
    left: 20px;
  }

  &:hover {
    border: none;
  }

`;

const QuitButton = styled(ButtonPrimary)`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #0E3859;
`;
