import gsap from "gsap/gsap-core";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ButtonSecondary } from "../../styles";
import { scrollToTop } from "../../utils";
import { dictionary } from "../../contexts/site";
import { IntroContainer, IntroWrapper, JobCode } from "./styles";
import AnimatedArrow from "../AnimatedArrow";

export default function Intro({ setShow = false, setName,  showStandalone}) {
  const history = useHistory();

  useEffect(() => {
    setName(null);
  }, [setName]);

  useEffect(() => {
    const elements = [...document.querySelectorAll(".fadeInUp")];

    const textAnimation = gsap.fromTo(
      elements,
      { autoAlpha: 0, y: 50 },
      {
        autoAlpha: 1,
        y: 0,
        duration: 0.5,
        stagger: 0.1,
      }
    );

    return () => {
      textAnimation.kill();
    };
  }, []);

  const fadeOutAnimation = () => {
    scrollToTop();
    gsap.to(".fadeOut", {
      duration: 0.5,
      opacity: 0,
    });
    gsap.to(".background-content-intro", {
      onComplete: () => {
        history.push("/Mary/0");
        setShow(true);
      }        
    });
  };

  return (
    <IntroContainer>
      <IntroWrapper>

        <img src="./pmb-lockup.svg" 
          aria-label={dictionary.intro.heading - dictionary.intro.heading2} 
          className="fadeInUp fadeOut heading2" />
        {/*
          <h1 className="fadeInUp fadeOut">{dictionary.intro.heading}</h1>
          <h2 className="fadeInUp fadeOut">{dictionary.intro.heading2}</h2>
        */}

        <p className="fadeInUp fadeOut">{dictionary.intro.paragraph1}</p>
        <p className="fadeInUp fadeOut">{dictionary.intro.paragraph2}</p>
       
        <ButtonSecondary
          aria-label={dictionary.intro.cta}
          className="fadeInUp fadeOut"
          onClick={() => fadeOutAnimation()}
          to="/Mary/0"
        >
          <AnimatedArrow text={dictionary.intro.cta} />
          {/*<span>{dictionary.intro.cta}</span>*/}
        </ButtonSecondary>

        {showStandalone && location.pathname === '/' && 
          <JobCode style={{"display": "none"}}>
            <label dangerouslySetInnerHTML={{__html: dictionary.stampStandalone}} />
          </JobCode>
        }

      </IntroWrapper>
    </IntroContainer>
  );
}
