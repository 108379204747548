import React, { useEffect } from 'react';

const videoUrls = [
    'Mary_door-1_corridor.mp4', 
    'Mary_door-1_idle-1.mp4',
    'Mary_door-1_idle-2.mp4',
    'Mary_door-1_idle-3.mp4',
    'Mary_door-1_idle-4.mp4',
    'Mary_door-1_idle-5.mp4',
    'Mary_door-1_incorrect-1.mp4',
    'Mary_door-1_incorrect-2.mp4',
    'Mary_door-1_incorrect-3.mp4',
    'Mary_door-1_incorrect-4.mp4',
    'Mary_door-1_incorrect-5.mp4',
    'Mary_door-2_corridor.mp4', 
    'Mary_door-2_idle-1.mp4',
    'Mary_door-2_idle-2.mp4',
    'Mary_door-2_idle-3.mp4',
    'Mary_door-2_idle-4.mp4',
    'Mary_door-2_idle-5.mp4',
    'Mary_door-2_incorrect-1.mp4',
    'Mary_door-2_incorrect-2.mp4',
    'Mary_door-2_incorrect-3.mp4',
    'Mary_door-2_incorrect-4.mp4',
    'Mary_door-2_incorrect-5.mp4',
    'Mary_door-3_corridor.mp4', 
    'Mary_door-3_idle-1.mp4',
    'Mary_door-3_idle-2.mp4',
    'Mary_door-3_idle-3.mp4',
    'Mary_door-3_idle-4.mp4',
    'Mary_door-3_idle-5.mp4',
    'Mary_door-3_incorrect-1.mp4',
    'Mary_door-3_incorrect-2.mp4',
    'Mary_door-3_incorrect-3.mp4',
    'Mary_door-3-incorrect-4.mp4',
    'Mary_door-3_incorrect-5.mp4',
    'Mary_door-4_correct.mp4',
    'Mary_door-4_corridor.mp4',
    'Mary_door-4_idle-3.mp4',
    'Mary_door-4_incorrect-3.mp4',
    'Mary_stage_final_fail.mp4',
    'Mary_stage_final_success.mp4'
];

const VideoPreloader = () => {
  useEffect(() => {
    videoUrls.forEach(url => {
      const video = document.createElement('video');
      video.src = '/videos/' + url;
      video.preload = 'metadata';
      video.style.display = 'none';
      video.style.width = '2700px';
      video.style.height = '1024px';
      document.body.appendChild(video);
    });
  }, []);

  return (
    <div>
      {/* Your component layout */}
    </div>
  );
};

export default VideoPreloader;