import gsap from "gsap/gsap-core";
import React, { useEffect, useState } from "react";
//import TagManager from "react-gtm-module";
import { dictionary } from "../../contexts/site";
import useProgress from "../../hooks/useProgress";
import { Container } from "../../styles";

import {
  ColumnWrapper,
  QRCode,
  QRCodeHeading,
  QRCodeLink,
  QRContent,
  ResultsWrapper,
  ScoreCard,
} from "./styles";

export default function Results({
  setActive,
  name,
  setName,
  answers = [],
  questions = [],
  setIsIdle,
}) {
  
  const { score, max } = useProgress(answers, questions);
  const [animationPlayed, setAnimationPlayed] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    setActive("results");
  }, [setActive]);

  useEffect(() => {
    setName(name);
  }, [setName, name]);

  useEffect(() => {
    if (animationPlayed) {
      gsap.fromTo(".fadeIn", { autoAlpha: 0 }, { autoAlpha: 1, stagger: 0.1 });
    }
  }, [animationPlayed]);

  useEffect(() => {

    /* Event Tracking 
    Event Category: Ecommerce
    Event Action: Add to Wishlist
    Event Name: Smartphone
    Event Value: 1000 */
    window._paq.push([
      'trackEvent', 
      'PBMSIM', 
      `Score ${name}`, 
      process.env.REACT_APP_IPAD === "true" ? 'IPad' : 'Touchscreen',
      `${score}`]);

    if (score <= 5) { // it was 6
      setMessage(dictionary.resultsPage.negative);
    } else if (score <= 8) { // it was 9
      setMessage(dictionary.resultsPage.positive);
    } else {
      setMessage(dictionary.resultsPage.success);
    }
  }, [score, name]);

  useEffect(() => {
    setIsIdle(false);
    setAnimationPlayed(true);

    /*setTimeout(() => {
      if (score <= 6) {
        const video = document.getElementById("currentVideo");

        if (video) {
          //video.setAttribute("src", `./videos/${name}_Stage_Final.mp4`);
          video.setAttribute("src", `./videos/${name}_stage_final_fail.mp4`);
          video.style.zIndex = "10";
          // change the intro animation when the user selects a different patient
          video.pause();
          video.currentTime = 0;
          video.play();
          video.addEventListener("ended", () => {
            setAnimationPlayed(true);
          });
        }
      } else {
        setAnimationPlayed(true);
      }
    }, 100);

    /*setTimeout(() => {
      if (score >= 7) {
        const video = document.getElementById("currentVideo");

        if (video) {
          video.setAttribute("src", `./videos/${name}_stage_final_success.mp4`);
          video.style.zIndex = "10";
          // change the intro animation when the user selects a different patient
          video.pause();
          video.currentTime = 0;
          video.play();
          video.addEventListener("ended", () => {
            setAnimationPlayed(true);
          });
        }
      } else {
        setAnimationPlayed(true);
      }
    }, 1000);*/

  }, [name, score, setIsIdle]);

  return (
    animationPlayed && (
      <Container className="fadeIn">
        <ResultsWrapper>
          <ColumnWrapper>
            <ScoreCard
              className="fadeIn"
              aria-label={`You scored ${score} out of ${max}`}
            >
              {dictionary.resultsPage.youScored}
              <br />
              {score} / {max}
            </ScoreCard>
            <hr />
            <p className="fadeIn">{message}</p>
            <hr />
            <QRContent>
              <QRCodeHeading dangerouslySetInnerHTML={{__html: dictionary.resultsPage.qrCode}}>
              </QRCodeHeading>
              <QRCode />
              <QRCodeLink>
                <strong>pbmirondeficiency.com</strong>
              </QRCodeLink>
            </QRContent>
          </ColumnWrapper>
        </ResultsWrapper>
      </Container>
    )
  );
}

